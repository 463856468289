import React, { useState, useEffect } from 'react'
import CryptoJS from 'crypto-js'
import Navbar from '../navbar'
import Swal from 'sweetalert2'
import useRazorpay from 'react-razorpay'
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Spinner
} from 'react-bootstrap'
import { useMutation, useLazyQuery } from '@apollo/client'
import {
  CREATE_RAZORPAY_ORDER,
  CREATE_RZ_CHECKOUT,
  CREATE_RZ_ORDER,
  SEND_MAIL_MEMBERSHIP,
  USER_REGISTER
} from '../../../graphql/Mutation'
import { useAlert } from 'react-alert'
import { useNavigate } from 'react-router-dom'
import { GoDotFill } from 'react-icons/go'

import { Link } from 'react-scroll'
import uniqid from 'uniqid'
import AWS from 'aws-sdk'
import { GET_PAYMENT_DETAILS } from '../../../graphql/Query'

const ID = process.env.REACT_APP_AWS_ACCESSS_ID
const SECRET = process.env.REACT_APP_AWS_ACCESSS_KEY
const BUCKET_NAME = process.env.REACT_APP_AWS_BUCKET_NAME
 // console.log('Key Id',keyId);
export default function Register () {
  let keyId = process.env.REACT_APP_RZ_PAY_KEY;
 
  const [Razorpay] = useRazorpay()
  const alert = useAlert()
  const navigation = useNavigate()

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const [validated, setValidated] = useState(false)
  const [emailExist, setEmailExist] = useState(false)

  const [createUser, { loading, error }] = useMutation(USER_REGISTER, {
    onError: e => {
      alert.error('Email already exists!!!')
    }
  })

  const [createRzOrder] = useMutation(CREATE_RAZORPAY_ORDER, {
    onError: e => {
      alert.error('Error During creating order.')
    }
  })

  const [createRZNewOrder] = useMutation(CREATE_RZ_ORDER, {
    onError: e => {
      alert.error(e.message)
    }
  })
  const [createRZNewCheckout] = useMutation(CREATE_RZ_CHECKOUT, {
    onError: e => {
      alert.error(e.message)
    }
  })

  const [sendMembershipMail] = useMutation(SEND_MAIL_MEMBERSHIP)
  const [verifyPayment] = useLazyQuery(GET_PAYMENT_DETAILS)
  const [name, setName] = useState('')
  const [fatherName, setfatherName] = useState('')
  const [dob, setDob] = useState('')
  const [occupation, setoccupation] = useState('')
  const [contact, setContact] = useState('')
  const [email, setEmail] = useState('')
  const [bloodGroup, setbloodGroup] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [pincode, setPincode] = useState('')
  const [idProof, setidProof] = useState('')
  const [order, setOrder] = useState(null)

  const [selectedImage, setSelectedImage] = useState('')
  const [passportPhoto, setPassportPhoto] = useState('')

  const [rClick, setRClick] = useState(false)

  const uploadFunction = (file, fileName, bucketName) => {
    return new Promise((resolve, reject) => {
      const s3 = new AWS.S3({
        accessKeyId: ID,
        secretAccessKey: SECRET
      })

      const params = {
        Bucket: bucketName,
        Key: fileName,
        Body: file
      }

      s3.upload(params, function (err, data) {
        if (err) {
          reject(err)
        } else {
          resolve(fileName)
        }
      })
    })
  }

  const handleSubmit = async event => {
    const uniqueId = uniqid()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
      event.preventDefault()
      event.stopPropagation()
    } else {
      setRClick(true)
      setValidated(false)
      event.preventDefault()
      try {
        let idProofImageName = ''
        let passportImageName = ''

        if (selectedImage) {
          var idProofExtension = selectedImage.name.match(new RegExp('[^.]+$'))
          var idProofFileName = uniqueId + '-idproof.' + idProofExtension[0]
          idProofImageName = await uploadFunction(
            selectedImage,
            idProofFileName,
            BUCKET_NAME
          )
        }

        if (passportPhoto) {
          var passportExtension = passportPhoto.name.match(new RegExp('[^.]+$'))
          var passportFileName = uniqueId + '-passport.' + passportExtension[0]
          passportImageName = await uploadFunction(
            passportPhoto,
            passportFileName,
            BUCKET_NAME
          )
        }

        createRzOrder({
          variables: {
            amount: 1000 * 100,
            currency: 'INR',
            receipt: `${email}`
          }
        })
          .then(res => {
            let {
              id,
              amount,
              status,
              currency,
              amount_due,
              amount_paid,
              attempts,
              notes,
              offer_id,
              receipt,
              created_at
            } = res.data.createOrder
            console.log('order_created',res);
            setOrder(res.data.createOrder)
            createRZNewOrder({
              variables: {
                orderInput: {
                  amount: amount,
                  amount_due: amount_due,
                  amount_paid: amount_paid,
                  attempts: attempts,
                  currency: `${currency}`,
                  entity: 'Order',
                  id: `${id}`,
                  notes: [`${notes}`],
                  receipt: `${receipt}`,
                  status: `${status}`,
                  offer_id: `${offer_id}`
                }
              }
            }).then(res => {
              console.log('order created -1', res)
              console.log('order created', res.data.createRZOrder)
              createUser({
                variables: {
                  userInput: {
                    fullName: `${name}`,
                    fatherName: `${fatherName}`,
                    dob: `${dob}`,
                    occupation: `${occupation}`,
                    contact: `${contact}`,
                    email: `${email}`,
                    bloodGroup: `${bloodGroup}`,
                    password: `${password}`,
                    addressLine1: `${addressLine1}`,
                    addressLine2: `${addressLine2}`,
                    city: `${city}`,
                    state: `${state}`,
                    pincode: `${pincode}`,
                    idProof: `${idProof}`,
                    attachIdProofImage: idProofImageName,
                    attachPassportImage: passportImageName,
                    payments: `${res.data.createRZOrder.id}`
                  }
                }
              })
                .then((res) => {
                  console.log('new error -1 ',res)
                  setRClick(false)
                  if (!res.data) {
                    alert.error(res.errors.message)
                    setEmailExist(true)
                    return
                  } else {
                    sendMembershipMail({
                      variables: {
                        email: `${email}`
                      }
                    })
                      .then(() => {
                        alert.success('Redirecting  to payment page...')
                        let actionButton =
                          document.querySelector('.rz_action_button')
                        actionButton.click()
                      })
                      .catch(err => {
                        alert.error(err.message)
                      })
                  }
                })
                .catch(err => {
                  setRClick(false)
                  alert.error(err.message)
                })
            })
          })
          .catch(err => {
            setRClick(false)
            alert.error(err.message)
          })
      } catch (err) {
        alert.error(err.message)
      }
    }
  }

  let stateArray = [
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttarakhand',
    'Uttar Pradesh',
    'West Bengal',
    'Andaman and Nicobar Islands',
    'Chandigarh',
    'Dadra and Nagar Haveli',
    'Daman and Diu',
    'Delhi',
    'Lakshadweep',
    'Puducherry'
  ]
  return (
    <>
      <Navbar />

      <form
        method='POST'
        action='https://api.razorpay.com/v1/checkout/embedded'
      >
        <input type='hidden' name='key_id' value={keyId} />
        <input type='hidden' name='amount' value={order ? order.amount : 0} />
        <input type='hidden' name='order_id' value={order ? order.id : ''} />
        <input type='hidden' name='name' value='CRO' />
        <input type='hidden' name='description' value='subscription for 1 year' />
        <input
          type='hidden'
          name='image'
          value='https://www.thecro.org/static/media/logo.b9d20dc1c662c93a0d41.png'
        />
        <input type='hidden' name='prefill[name]' value={name} />
        <input type='hidden' name='prefill[contact]' value={contact} />
        <input type='hidden' name='prefill[email]' value={email} />
        <input
          type='hidden'
          name='notes[shipping address]'
          value={addressLine1}
        />
        <input type='hidden' name='callback_url' value='https://cro-backend.vercel.app/payment/success' />
        {/* <input type="hidden" name="callback_url" value="http://localhost:4000/payment/success" /> */}
        <input type='hidden' name='cancel_url' value='https://cro-backend.vercel.app/payment/fail' />
        {/* <input type="hidden" name="cancel_url" value="http://localhost:3000/payment/fail" /> */}
        <button className='rz_action_button'>Submit</button>
      </form>

      <Container style={{ marginTop: 120 }} fluid>
        <section id='services' className='services'>
          <Container>
            <div className='section-title' data-aos='fade-up'>
              <p style={{ fontFamily: 'poppins' }}>Membership Form</p>
            </div>
            <Link
              to='registerId'
              spy={true}
              offset={-130}
              duration={500}
              style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}
            >
              <Button
                type='submit'
                className='mx-auto d-block loginBtnn'
                style={{ marginBottom: 20, marginTop: '-20px' }}
              >
                Register Now
              </Button>
            </Link>
            <Row>
              <Col>
                <div className='icon-box' style={{ height: 550 }}>
                  <h4 className='title fs-1 text-primary'>
                    Who can become a member
                  </h4>
                  <p className='description'>
                    Any person of India who is working under any category in
                    Central or state government or in semi-government or in
                    private firm, all farmers, all public community and all
                    those persons who are somehow consumer can be the Member of
                    UPBHOKTA ADHIKAR SANGTHAN (CRO - Consumer Rights
                    Organisation).
                    <br />
                    CRO/Upbhokta Adhikar Sangthan whose work area is whole
                    India. Any person who is citizen of India can be member of
                    this organisation.
                  </p>
                </div>
              </Col>
              <Col>
                <div className='icon-box' style={{ height: 550 }}>
                  <h4 className='title fs-1 text-primary'>
                    Eligibility to become a member
                  </h4>
                  <p className='description text-start'>
                    <GoDotFill style={{ color: 'f05a4d' }} /> Person should not
                    be less than 18 years of age.
                    <br />
                    <GoDotFill style={{ color: 'f05a4d' }} /> Person should be a
                    citizen of India.
                    <br />
                    <GoDotFill style={{ color: 'f05a4d' }} /> Person should
                    pledge to follow the rules of the organization.
                    <br />
                    <GoDotFill style={{ color: 'f05a4d' }} /> Person should be
                    ready to pay membership fees.
                    <br />
                    <GoDotFill style={{ color: 'f05a4d' }} /> Person should be
                    of Good character.
                  </p>
                </div>
              </Col>
              <Col>
                <div className='icon-box' style={{ height: 550 }}>
                  <h4 className='title fs-1 text-primary'>
                    Benefits of becoming a member
                  </h4>
                  <p className='description text-start'>
                    <GoDotFill style={{ color: 'f05a4d' }} /> You Will Be
                    Provided An Identity Card.
                    <br />
                    <GoDotFill style={{ color: 'f05a4d' }} /> You Can File No.
                    Of Complaints In A Year.
                    <br />
                    <GoDotFill style={{ color: 'f05a4d' }} /> You Can Become A
                    Consumer Activist By Participating In CRO Activities.
                    <br />
                    <GoDotFill style={{ color: 'f05a4d' }} /> You Will Be Aware
                    Of New Rules And Regulations Related To Consumers.
                    <br />
                    <GoDotFill style={{ color: 'f05a4d' }} /> You Are Entitled
                    For Training Programs Conducted By Organisation With Various
                    Departments.
                    <br />
                    <GoDotFill style={{ color: 'f05a4d' }} /> Informative News
                    Letter Will Be Sent Periodically.
                    <br />
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <div id='registerId'>
          <Row>
            <Col>
              <Card
                style={{
                  boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                }}
              >
                <Card.Body>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Row className='mb-3'>
                      <Form.Group
                        as={Col}
                        md='4'
                        controlId='validationCustom01'
                      >
                        <Form.Label
                          style={{
                            fontFamily: 'poppins',
                            fontSize: 12,
                            fontWeight: 'bold'
                          }}
                        >
                          Full Name
                        </Form.Label>
                        <Form.Control
                          style={{ fontFamily: 'poppins', fontSize: 12 }}
                          required
                          type='text'
                          placeholder='Full Name'
                          onChange={e => setName(e.target.value)}
                          value={name}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md='4'
                        controlId='validationCustom01'
                      >
                        <Form.Label
                          style={{
                            fontFamily: 'poppins',
                            fontSize: 12,
                            fontWeight: 'bold'
                          }}
                        >
                          Fathers Name
                        </Form.Label>
                        <Form.Control
                          style={{ fontFamily: 'poppins', fontSize: 12 }}
                          required
                          type='text'
                          placeholder='Fathers Name'
                          onChange={e => setfatherName(e.target.value)}
                          value={fatherName}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md='4'
                        controlId='validationCustom02'
                      >
                        <Form.Label
                          style={{
                            fontFamily: 'poppins',
                            fontSize: 12,
                            fontWeight: 'bold'
                          }}
                        >
                          DOB
                        </Form.Label>
                        <Form.Control
                          style={{ fontFamily: 'poppins', fontSize: 12 }}
                          required
                          type='date'
                          onChange={e => setDob(e.target.value)}
                          value={dob}
                        />
                      </Form.Group>
                    </Row>

                    <Row className='mb-3'>
                      <Form.Group
                        as={Col}
                        md='4'
                        controlId='validationCustom02'
                      >
                        <Form.Label
                          style={{
                            fontFamily: 'poppins',
                            fontSize: 12,
                            fontWeight: 'bold'
                          }}
                        >
                          Occupation
                        </Form.Label>
                        <Form.Control
                          style={{ fontFamily: 'poppins', fontSize: 12 }}
                          required
                          type='text'
                          placeholder='Occupation'
                          onChange={e => setoccupation(e.target.value)}
                          value={occupation}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md='4'
                        controlId='validationCustom02'
                      >
                        <Form.Label
                          style={{
                            fontFamily: 'poppins',
                            fontSize: 12,
                            fontWeight: 'bold'
                          }}
                        >
                          Mobile No.
                        </Form.Label>
                        <Form.Control
                          style={{ fontFamily: 'poppins', fontSize: 12 }}
                          required
                          type='number'
                          placeholder='Contact'
                          onChange={e => setContact(e.target.value)}
                          value={contact}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md='4'
                        controlId='validationCustom02'
                      >
                        <Form.Label
                          style={{
                            fontFamily: 'poppins',
                            fontSize: 12,
                            fontWeight: 'bold'
                          }}
                        >
                          Email ID
                        </Form.Label>
                        <Form.Control
                          style={{ fontFamily: 'poppins', fontSize: 12 }}
                          required
                          type='email'
                          placeholder='Email'
                          onChange={e => setEmail(e.target.value)}
                          value={email}
                        />
                      </Form.Group>
                    </Row>

                    <Row className='mb-3'>
                      <Form.Group
                        as={Col}
                        md='4'
                        controlId='validationCustom02'
                      >
                        <Form.Label
                          style={{
                            fontFamily: 'poppins',
                            fontSize: 12,
                            fontWeight: 'bold'
                          }}
                        >
                          Blood group
                        </Form.Label>
                        <Form.Control
                          style={{ fontFamily: 'poppins', fontSize: 12 }}
                          required
                          type='text'
                          placeholder='Blood group'
                          onChange={e => setbloodGroup(e.target.value)}
                          value={bloodGroup}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md='4'
                        controlId='validationCustom01'
                      >
                        <Form.Label
                          style={{
                            fontFamily: 'poppins',
                            fontSize: 12,
                            fontWeight: 'bold'
                          }}
                        >
                          Password
                        </Form.Label>
                        <Form.Control
                          style={{ fontFamily: 'poppins', fontSize: 12 }}
                          required
                          type='password'
                          placeholder='Password'
                          onChange={e => setPassword(e.target.value)}
                          value={password}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md='4'
                        controlId='validationCustom02'
                      >
                        <Form.Label
                          style={{
                            fontFamily: 'poppins',
                            fontSize: 12,
                            fontWeight: 'bold'
                          }}
                        >
                          Confirm Password
                        </Form.Label>
                        <Form.Control
                          style={{ fontFamily: 'poppins', fontSize: 12 }}
                          required
                          type='text'
                          placeholder='Confirm Password'
                          onChange={e => setConfirmPassword(e.target.value)}
                          value={confirmPassword}
                        />
                      </Form.Group>
                    </Row>

                    <Row className='mb-3'>
                      <Form.Group
                        as={Col}
                        md='12'
                        className='mb-3'
                        controlId='validationCustom02'
                      >
                        <Form.Label
                          style={{
                            fontFamily: 'poppins',
                            fontSize: 12,
                            fontWeight: 'bold'
                          }}
                        >
                          Address
                        </Form.Label>
                        <Form.Control
                          style={{ fontFamily: 'poppins', fontSize: 12 }}
                          required
                          as='textarea'
                          placeholder='Address line 1...'
                          onChange={e => setAddressLine1(e.target.value)}
                          value={addressLine1}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md='12'
                        controlId='validationCustom02'
                      >
                        <Form.Control
                          style={{ fontFamily: 'poppins', fontSize: 12 }}
                          required
                          as='textarea'
                          placeholder='Address line 2...'
                          onChange={e => setAddressLine2(e.target.value)}
                          value={addressLine2}
                        />
                      </Form.Group>
                    </Row>

                    <Row className='mb-3'>
                      <Form.Group
                        as={Col}
                        md='4'
                        controlId='validationCustom02'
                      >
                        <Form.Label
                          style={{
                            fontFamily: 'poppins',
                            fontSize: 12,
                            fontWeight: 'bold'
                          }}
                        >
                          City
                        </Form.Label>
                        <Form.Control
                          style={{ fontFamily: 'poppins', fontSize: 12 }}
                          required
                          type='text'
                          placeholder='City'
                          onChange={e => setCity(e.target.value)}
                          value={city}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md='4'
                        controlId='validationCustom02'
                      >
                        <Form.Label
                          style={{
                            fontFamily: 'poppins',
                            fontSize: 12,
                            fontWeight: 'bold'
                          }}
                        >
                          State
                        </Form.Label>
                        <Form.Control
                          required
                          as='select'
                          style={{ fontFamily: 'poppins', fontSize: 12 }}
                          onChange={e => setState(e.target.value)}
                          value={state}
                        >
                          <option
                            value=''
                            selected='selected'
                            disabled='disabled'
                          >
                            Choose State
                          </option>
                          {stateArray.map(stateData => (
                            <option value={stateData}>{stateData}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md='4'
                        controlId='validationCustom02'
                      >
                        <Form.Label
                          style={{
                            fontFamily: 'poppins',
                            fontSize: 12,
                            fontWeight: 'bold'
                          }}
                        >
                          Pincode
                        </Form.Label>
                        <Form.Control
                          style={{ fontFamily: 'poppins', fontSize: 12 }}
                          required
                          type='number'
                          placeholder='Pincode'
                          onChange={e => setPincode(e.target.value)}
                          value={pincode}
                        />
                      </Form.Group>
                    </Row>

                    <Row className='mb-3'>
                      <Form.Group
                        as={Col}
                        md='4'
                        controlId='validationCustom02'
                      >
                        <Form.Label
                          style={{
                            fontFamily: 'poppins',
                            fontSize: 12,
                            fontWeight: 'bold'
                          }}
                        >
                          Id proof
                        </Form.Label>
                        <Form.Select
                          defaultValue='Choose...'
                          style={{ fontSize: '.8rem' }}
                        >
                          <option
                            onChange={e => setidProof(e.target.value)}
                            value={idProof}
                            selected='selected'
                            disabled='disabled'
                          >
                            Choose ID proof
                          </option>
                          <option value='Aadhar'>Aadhar</option>
                          <option value='Driving license'>
                            Driving license
                          </option>
                          <option value='Passport'>Passport</option>
                          <option value='Voter ID'>Voter ID</option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md='4'
                        controlId='validationCustom02'
                      >
                        <Form.Label
                          style={{
                            fontFamily: 'poppins',
                            fontSize: 12,
                            fontWeight: 'bold'
                          }}
                        >
                          Attach ID Proof
                        </Form.Label>
                        <Form.Control
                          style={{ fontFamily: 'poppins', fontSize: 12 }}
                          required
                          type='file'
                          onChange={event => {
                            setSelectedImage(event.target.files[0])
                          }}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md='4'
                        controlId='validationCustom03'
                      >
                        <Form.Label
                          style={{
                            fontFamily: 'poppins',
                            fontSize: 10,
                            fontWeight: 'bold'
                          }}
                        >
                          For issuing an ID card upload passport size latest
                          photo
                        </Form.Label>
                        <Form.Control
                          style={{ fontFamily: 'poppins', fontSize: 12 }}
                          required
                          type='file'
                          onChange={event => {
                            setPassportPhoto(event.target.files[0])
                          }}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <h5 className='section-heading complaintText2'>
                          Declaration
                        </h5>
                        <div
                          className='d-flex align-center justify-content-between'
                          style={{ background: '#F3F4F6', padding: '2rem' }}
                        >
                          <div className='col-sm-12'>
                            <div
                              style={{
                                background: '#fff',
                                padding: '2rem',
                                borderRadius: '15px',
                                height: '200px',
                                overflow: 'hidden',
                                overflowY: 'auto'
                              }}
                            >
                              <p>
                                To, <br />
                                UPBHOKTA ADHIKAR SANGTHAN (CRO-CONSUMER RIGHTS
                                ORGANISATION)
                              </p>
                              <p>
                                Sir, I want to take the Membership of the
                                UPBHOKTA ADHIKAR SANGTHAN (CRO-CONSUMER RIGHTS
                                ORGANISATION) for which I am submitting all the
                                required details &amp; Membership fee of Rs
                                1000/- (INR One Thousand Rs. Only). Therefore,
                                kindly register me as a Member of the
                                Organization.
                              </p>
                              <p>
                                I hereby declare that I am ready to abide by the
                                rules and sub rules of the Consumer Rights
                                Organisation in their Present / Current form or
                                when they are updated from time to time.
                              </p>
                              <p>
                                I promise to be loyal towards the mission of the
                                organisation and would follow all those rules
                                and regulations which are given to me by
                                organisation to fulfil its mission.
                              </p>
                            </div>
                            <Form.Group className='mt-4'>
                              <Form.Check
                                required
                                label='Accept rules and conditions'
                                feedback='You must agree before submitting.'
                                feedbackType='invalid'
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {emailExist ? <center><h4 className='text-danger mt-3'>Email already exist!!!</h4></center> : ''}
                    {loading || rClick ? (
                      <Button
                        type='submit'
                        className='mx-auto d-block'
                        style={{
                          background: '#FF5F3F',
                          border: 'none',
                          marginTop: 20,
                          fontFamily: 'poppins'
                        }}
                        disabled
                      >
                        Register
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                          style={{ marginLeft: 5 }}
                        />
                      </Button>
                    ) : (
                      <Button
                        type='submit'
                        className='mx-auto d-block'
                        style={{
                          background: '#FF5F3F',
                          border: 'none',
                          marginTop: 20,
                          fontFamily: 'poppins'
                        }}
                      >
                        Register{' '}
                      </Button>
                    )}
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}
