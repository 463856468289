import React from 'react'
import Ashish from '../../assets/team/team-6.jpg'
import Navin from '../../assets/team/team-3.jpg'
import Hemanth from '../../assets/img/team/hemath.jpg'
import shailash from '../../assets/team/team-5.jpg'
import deepak from '../../assets/team/team-2.jpg'
import naveen from '../../assets/team/team-1.jpg'
import DSattibabu from '../../assets/team/team-4.jpg'
import MAruna from '../../assets/team/team-7.jpg'
import { Container, Row, Col, Image } from 'react-bootstrap'

// import '../../assets/css/theme.css'
// import '../../assets/css/newstyle.css'

const Team = () => {
    return (
        <>
            <section id="team" className="team" name="team-section">
                <Container>

                    <div className="section-title" data-aos="fade-up">
                        <h2>Team</h2>
                        <p>Our team is always here to help</p>
                    </div>

                    <Row>

                        <Col md={3}>
                            <Image className="mx-auto d-block" src={naveen} alt="" fluid  style={{borderRadius:20}}/>
                            <h5 className='mb-1' style={{ fontFamily: 'poppins', fontSize: 15, fontWeight: 'bold', textAlign: 'center', marginTop: 10 }}>Naveen Prakash Sharma (Advocate)</h5>
                            <h5 style={{ fontFamily: 'poppins', fontSize: 13, textAlign: 'center' }}>Founder & Legal Head</h5>
                        </Col>
                        <Col md={3}>
                            <Image className="mx-auto d-block" src={deepak} alt="" fluid />
                            <h5 className='mb-1' style={{ fontFamily: 'poppins', fontSize: 15, fontWeight: 'bold', textAlign: 'center', marginTop: 10 }}>Deepak Varma</h5>
                            <h5 style={{ fontFamily: 'poppins', fontSize: 13, textAlign: 'center' }}>Incharge-Complaint</h5>
                        </Col>
                        <Col md={3}>
                            <Image className="mx-auto d-block" src={Ashish} alt="" fluid />
                            <h5 className='mb-1' style={{ fontFamily: 'poppins', fontSize: 15, fontWeight: 'bold', textAlign: 'center', marginTop: 10 }}>Ashish Vegad</h5>
                            <h5 style={{ fontFamily: 'poppins', fontSize: 13, textAlign: 'center' }}>Incharge-Public relations</h5>
                            <h5 style={{ fontFamily: 'poppins', fontSize: 13, textAlign: 'center' }}>(North region)</h5>
                        </Col>
                        <Col md={3}>
                            <Image className="mx-auto d-block" src={shailash} alt="" fluid />
                            <h5 className='mb-1' style={{ fontFamily: 'poppins', fontSize: 15, fontWeight: 'bold', textAlign: 'center', marginTop: 10 }}>Shailesh Agarwal</h5>
                            <h5 style={{ fontFamily: 'poppins', fontSize: 13, textAlign: 'center' }}>Incharge-Public relations</h5>
                            <h5 style={{ fontFamily: 'poppins', fontSize: 13, textAlign: 'center' }}>(West region)</h5>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col md={3}>
                            <Image className="mx-auto d-block" src={Navin} alt="" fluid />
                            <h5 className='mb-1' style={{ fontFamily: 'poppins', fontSize: 15, fontWeight: 'bold', textAlign: 'center', marginTop: 10 }}>Naveen Kawal</h5>
                            <h5 style={{ fontFamily: 'poppins', fontSize: 13, textAlign: 'center' }}>Incharge-Public relations</h5>
                            <h5 style={{ fontFamily: 'poppins', fontSize: 13, textAlign: 'center' }}>(South region)</h5>
                        </Col>
                        {/* <Col md={3}>
                            <Image className="mx-auto d-block" src={Hemanth} alt="" fluid />
                            <h5 className='mb-1' style={{ fontFamily: 'poppins', fontSize: 15, fontWeight: 'bold', textAlign: 'center', marginTop: 10 }}>Hemant Joshi (Advocate)</h5>
                            <h5 style={{ fontFamily: 'poppins', fontSize: 13, textAlign: 'center' }}>Associate Advocate</h5>
                        </Col> */}
                        <Col md={3}>
                            <Image className="mx-auto d-block" height={120} src={DSattibabu} alt="" fluid />
                            <h5 className='mb-1' style={{ fontFamily: 'poppins', fontSize: 15, fontWeight: 'bold', textAlign: 'center', marginTop: 10 }}>D Sattibabu (Advocate)</h5>
                            <h5 style={{ fontFamily: 'poppins', fontSize: 13, textAlign: 'center' }}>Associate Advocate</h5>
                        </Col>
                        <Col md={3}>
                            <Image className="mx-auto d-block" height={120} src={MAruna} alt="" fluid />
                            <h5 className='mb-1' style={{ fontFamily: 'poppins', fontSize: 15, fontWeight: 'bold', textAlign: 'center', marginTop: 10 }}>M Aruna shree (Advocate)</h5>
                            <h5 style={{ fontFamily: 'poppins', fontSize: 13, textAlign: 'center' }}>Associate Advocate</h5>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Team