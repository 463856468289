/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../../assets/css/theme.css";
import logo from "../../assets/img/logo.png";
import "../../assets/js/bootstrap-navbar";

import { Link } from "react-router-dom";

const Navbar = () => {
  const userToken = localStorage.getItem("userToken");
  const adminToken = localStorage.getItem("superAdminToken");
  return (
    <>
      <nav
        style={{ background: "#FFFFFF" }}
        className="navbar navbar-expand-lg navbar-light fixed-top py-2 d-block"
        data-navbar-on-scroll="data-navbar-on-scroll"
      >
        <div className="container-fluid">
          <div className="d-flex" style={{ alignItems: "center" }}>
            <Link className="navbar-brand" to="/organisation">
              <img
                className="d-inline-block mb-2"
                src={logo}
                alt=""
                style={{ width: "60px" }}
              />
            </Link>
            <div>
              <h5
                className="me-3 logoText"
                style={{ fontSize: "1.29rem", marginBottom: "0" }}
              >
                CONSUMER
              </h5>
              <h5 className="me-3 logoText" style={{ fontSize: "1.11rem" }}>
                Complaint Cell
              </h5>
            </div>
          </div>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse border-lg-0 mt-4 mt-lg-0"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav m-auto pt-2 pt-lg-0 font-base">
              <Link
                to="/"
                style={{
                  fontSize: 17,
                  fontFamily: "Poppins",
                  fontWeight: "500",
                }}
                className="nav-link"
              >
                <li className="nav-item px-2" data-anchor="data-anchor">
                  Home
                </li>
              </Link>
              <Link
                to="/consumer-right-complaint-about/about"
                style={{
                  fontSize: 17,
                  fontFamily: "Poppins",
                  fontWeight: "500",
                }}
                className="nav-link"
              >
                <li className="nav-item px-2" data-anchor="data-anchor">
                  About
                </li>
              </Link>
              <Link
                to="/complaint/register-a-consumer-complaint-with-consumer-right-helpline-thecro"
                style={{
                  fontSize: 17,
                  fontFamily: "Poppins",
                  fontWeight: "500",
                }}
                className="nav-link"
              >
                <li className="nav-item px-2" data-anchor="data-anchor">
                  Complaint
                </li>
              </Link>
              {/* <Link to="/register" style={{ fontSize: 17, fontFamily: 'Poppins', fontWeight: 'bold' }} className="nav-link"><li className="nav-item px-2" data-anchor="data-anchor">Register</li></Link> */}
              <Link
                to="/complaint/contact"
                style={{
                  fontSize: 17,
                  fontFamily: "Poppins",
                  fontWeight: "500",
                }}
                className="nav-link"
              >
                <li className="nav-item px-2" data-anchor="data-anchor">
                  Contact Us
                </li>
              </Link>
            </ul>
            <form className="ps-lg-5 p-0">
              {adminToken ? (
                <button
                  style={{
                    fontSize: 15,
                    fontFamily: "poppins",
                    textDecoration: "none",
                  }}
                  className="btn btn-link text-danger btn-collab fw-bold order-1 order-lg-0"
                  type="button"
                >
                  <Link
                    style={{ color: "#fff" ,fontSize:14}}
                    to="/complaint/adminPanel/superAdminDashboardView"
                  >
                    Dashboard
                  </Link>
                </button>
              ) : userToken ? (
                <button
                  style={{
                    fontSize: 17,
                    fontFamily: "poppins",
                    textDecoration: "none",
                  }}
                  className="btn btn-link btn-collab text-danger fw-bold profileBtn"
                  type="button"
                >
                  <Link
                    style={{ color: "#fff" }}
                    to="/complaint/userDashboard/pendingComplaintsUser"
                  >
                    Profile
                  </Link>
                </button>
              ) : (
                <button
                  style={{
                    fontSize: 17,
                    fontFamily: "poppins",
                    textDecoration: "none",
                  }}
                  className="btn btn-link text-danger btn-collab fw-bold order-1 order-lg-0"
                  type="button"
                >
                  <Link style={{ color: "#fff" }} to="/complaint/userLogin">
                    Sign in
                  </Link>
                </button>
              )}
              {/* <Link to="/complaint" style={{ fontSize: 17, fontFamily: 'poppins' }} className="btn hover-top btn-collab"><PiNotePencilFill style={{ marginTop: '-4px' }} /> File a Complaints</Link> */}
            </form>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
