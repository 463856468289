import React from "react";
import heroHeaderGif from "../../assets/img/gallery/hero-header.gif";
import "../../assets/css/theme.css";
import { PiNotePencilFill } from "react-icons/pi";
import { BiSolidUserCheck } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";

const Home = () => {

 const navigate = useNavigate();
  return (
    <>
     <Helmet>
            <title>Consumer Complaint</title>
            <meta name="description" content="Submit and view consumer complaints easily."/>
        </Helmet>
      <section id="home">
        <div className="container" style={{ marginTop: 50 }}>
          <div className="row align-items-center g-2">
            <div className="col-md-5 col-lg-6 order-0 order-md-1 text-end">
              <img
                className="pt-7 pt-md-0 w-100"
                src={heroHeaderGif}
                alt="hero-header"
              />
            </div>
            <div className="col-md-7 col-lg-6 py-6 text-md-start text-center">
              {/* <!-- <h6 className="fs-0 text-uppercase fw-bold text-600">Top Business App</h6> --> */}
              <h1
                className="fw-bold fs-4 fs-lg-4 fs-xxl-7"
                style={{ fontSize: "12px", fontFamily: "poppins" }}
              >
                Welcome to the most trusted platform for resolving{" "}
              </h1>
              <p
                className="fw-bold mb-5 fs-4 fw-medium text-primary"
                style={{ fontSize: "12px", fontFamily: "poppins" }}
              >
                consumer complaint
                <br className="d-none d-xl-block fs-6" />
              </p>
              <button
                style={{ fontFamily: "Poppins", width: "230px" }}
                className="btn hover-top btn-collab m-2"
              
                onClick={()=>navigate('/complaint/register-a-consumer-complaint-with-consumer-right-helpline-thecro')}
              >
                <PiNotePencilFill
                  style={{ marginTop: "-2px", marginRight: 10 }}
                />{" "}
                File a Complaint
              </button>
              <Link
                style={{ fontFamily: "Poppins", width: "230px" }}
                className="btn hover-top btn-collab m-2"
                to="/complaint/register"
              >
                <BiSolidUserCheck
                  style={{ marginTop: "-2px", fontWeight: "800" }}
                />{" "}
                Become a member{" "}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
