import React,{useEffect} from "react";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  split,
  ApolloProvider,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "@apollo/client/link/ws";

import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";



const httpLink = new HttpLink({
  // uri: "http://localhost:4000/graphql",
 uri: "https://cro-backend.vercel.app/graphql",
});

const wsLink = new WebSocketLink({
  // uri: "wss:localhost:4000/graphql",
   uri: "wss:cro-backend.vercel.app/graphql",
  options: {
    reconnect: true, 
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([splitLink]),
});

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
};






const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider template={AlertTemplate} {...options}>
        <App />
      </Provider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
