import React, { useState, useEffect, useRef } from "react";
import Navbar from "./navbar";
import Lottie from "react-lottie";
import * as animationData from "../../assets/img/icons/comp.json";
import Swal from "sweetalert2";
import {
  Form,
  Row,
  Col,
  Container,
  Card,
  Button,
  Table,
  Spinner,
} from "react-bootstrap";
import Footer from "./footer";
import { useNavigate } from "react-router-dom";
import UserLoginComplaint from "./user/UserLoginComplain";
import {
  CREATE_COMPLAINT,
  MUTATION_EMAIL,
  SEND_MAIL_COMPLAINTS,
  UPLOAD_FILE,
} from "../../graphql/Mutation";
import {
  GET_USER_BY_ID,
  GET_PENDING_COMPALINT,
  GET_ACCEPTED_COMPALINT,
  GET_DECLINED_COMPALINT,
  GET_PAID_COMPALINT,
  GET_UNPAID_COMPALINT,
  GET_RESOLVED_COMPALINT,
} from "../../graphql/Query";
import { useMutation } from "@apollo/client";
import "../../assets/css/complaints.css";

import uniqid from "uniqid";
import AWS from "aws-sdk";
import { useAlert } from "react-alert";

const ID = process.env.REACT_APP_AWS_ACCESSS_ID;
const SECRET = process.env.REACT_APP_AWS_ACCESSS_KEY;
const BUCKET_NAME = process.env.REACT_APP_AWS_BUCKET_NAME;

const Complaints = () => {
  const navigation = useNavigate();
  const alert = useAlert();
  const userToken = localStorage.getItem("userToken");

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [addComplaint, { loading }] = useMutation(CREATE_COMPLAINT, {
    refetchQueries: [
      GET_PENDING_COMPALINT,
      GET_ACCEPTED_COMPALINT,
      GET_DECLINED_COMPALINT,
      GET_PAID_COMPALINT,
      GET_UNPAID_COMPALINT,
      GET_RESOLVED_COMPALINT,
    ],
  });

  const [sendComplaintsMail] = useMutation(SEND_MAIL_COMPLAINTS);

  // if (loading) {
  //     <Spinner animation="border" role="status">
  //         <span className="visually-hidden">Loading...</span>
  //     </Spinner>
  // }

  const [yourProviderName, setyourProviderName] = useState("");
  const [yourEmail, setyourEmail] = useState("");
  const [yourContact, setyourContact] = useState("");
  const [concern, setconcern] = useState("");
  const [productName, setproductName] = useState("");
  const [yourFullAddress, setyourFullAddress] = useState("");
  const [yourCity, setyourCity] = useState("");
  const [yourState, setyourState] = useState("");
  const [yourPincode, setyourPincode] = useState("");
  const [haveYouComplaintToAnother, sethaveYouComplaintToAnother] =
    useState(false);
  const [haveYouComplaintToAnotherText, sethaveYouComplaintToAnotherText] =
    useState("");
  const [haveStartCourtActionText, sethaveStartCourtActionText] = useState("");
  const [productValue, setproductValue] = useState("");
  const [howYouLikeComplaintSolve, sethowYouLikeComplaintSolve] = useState("");
  const [complaintType, setcomplaintType] = useState("");
  const [complaintDescription, setcomplaintDescription] = useState("");
  const [whenProblemOccurDate, setwhenProblemOccurDate] = useState("");
  const [whenProblemOccurTime, setwhenProblemOccurTime] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhone_number] = useState("");

  // const [whoNameofOpposite, setwhoNameofOpposite] = useState("")
  // const [haveStartCourtAction, sethaveStartCourtAction] = useState(false)
  // const [didSignAggrement, setdidSignAggrement] = useState(false)

  const uploadFunction = (file, fileName, bucketName) => {
    return new Promise((resolve, reject) => {
      const s3 = new AWS.S3({
        accessKeyId: ID,
        secretAccessKey: SECRET,
      });
      const params = {
        Bucket: bucketName,
        Key: fileName,
        Body: file,
      };
      s3.upload(params, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(fileName);
        }
      });
    });
  };

  const [resolutionValue, setResolutionValue] = useState([]);
  function handleChangeforResolution(e) {
    const checked = e.target.checked;
    const value = e.target.name;

    if (checked) {
      setResolutionValue((prevState) => [...prevState, value]);
    } else {
      setResolutionValue((prevState) =>
        prevState.filter((color) => color !== value)
      );
    }
  }

  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState([]);
  const fileInputRef = useRef(null);
  const [imageLoading, setImageLoading] = useState(false);

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
    setImageLoading(false);
  };

  const handleUploadImage = async () => {
    if (selectedImage) {
      setImageLoading(true);
      try {
        const uniqueId = uniqid();

        const idProofExtension = selectedImage.name.match(new RegExp("[^.]+$"));
        const idProofFileName = uniqueId + "-idproof." + idProofExtension[0];
        const idProofImageName = await uploadFunction(
          selectedImage,
          idProofFileName,
          BUCKET_NAME
        );

        setImages([...images, idProofImageName]);

        setSelectedImage(null);
        fileInputRef.current.value = "";
        setImageLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const [createMail] = useMutation(MUTATION_EMAIL);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (resolutionValue.length === 0) {
      setValidated(false);
      event.preventDefault();
      alert.error("Resolution is required.");
      return;
    }
    if (form.checkValidity() === false) {
      setValidated(true);
      alert.error("all filled's are required ");
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      try {
        // let idProofImageName = "";
        // if (selectedImage) {
        //     var idProofExtension = selectedImage.name.match(new RegExp('[^.]+$'));
        //     var idProofFileName = uniqueId + "-idproof." + idProofExtension[0];
        //     idProofImageName = await uploadFunction(selectedImage, idProofFileName, BUCKET_NAME);
        // }
        addComplaint({
          variables: {
            complaintInput: {
              userId: userId ? `${userId}` : null,
              yourProviderName: `${yourProviderName}`,
              yourEmail: `${yourEmail}`,
              yourContact: `${yourContact}`,
              concernPerson: `${concern}`,
              productName: `${productName}`,
              yourFullAddress: `${yourFullAddress}`,
              yourCity: `${yourCity}`,
              yourState: `${yourState}`,
              yourPincode: `${yourPincode}`,
              haveYouComplaintToAnother: `${haveYouComplaintToAnother}`,
              haveYouComplaintToAnotherText: `${haveYouComplaintToAnotherText}`,
              resolution: resolutionValue,
              productValue: `${productValue}`,
              image: images,
              whenProblemOccurDate: `${whenProblemOccurDate}`,
              haveStartCourtActionText: `${haveStartCourtActionText}`,
              complaintType: `${complaintType}`,
              complaintDescription: `${complaintDescription}`,
              howYouLikeComplaintSolve: `${howYouLikeComplaintSolve}`,
              whenProblemOccurTime: `${whenProblemOccurTime}`,
              fullName: `${fullName}`,
              email: `${email}`,
              phone_number: `${phone_number}`,
            },
          },
        })
          .then(function () {
            const EmailhtmlTemplate = `
           <!doctype html>
           <html lang="en-US">
           
           <head>
               <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
               <title>Appointment Reminder Email Template</title>
               <meta name="description" content="Appointment Reminder Email Template">
           </head>
           <style>
               a:hover {text-decoration: underline !important;}
           </style>
           
           <body marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
               <table cellspacing="0" border="0" cellpadding="0" width="100%" bgcolor="#f2f3f8"
                   style="@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700|Open+Sans:300,400,600,700); font-family: 'Open Sans', sans-serif;">
                   <tr>
                       <td>
                           <table style="background-color: #f2f3f8; max-width:670px; margin:0 auto;" width="100%" border="0"
                               align="center" cellpadding="0" cellspacing="0">
                               <tr>
                                   <td style="height:80px;">&nbsp;</td>
                               </tr>
                               <!-- Logo -->
                               <tr>
                                   <td style="text-align:center;">
                                     <a href="https://rakeshmandal.com" title="logo" target="_blank">
                                       <img width="80" src="https://growhealthproimg.s3.ap-south-1.amazonaws.com/logo.png" title="logo" alt="logo">
                                     </a>
                                   </td>
                               </tr>
                               <tr>
                                   <td style="height:20px;">&nbsp;</td>
                               </tr>
                               <!-- Email Content -->
                               <tr>
                                   <td>
                                       <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                           style="max-width:670px; background:#fff; border-radius:3px;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);padding:0 40px;">
                                           <tr>
                                               <td style="height:40px;">&nbsp;</td>
                                           </tr>
                                           <!-- Title -->
                                           <tr>
                                               <td style="padding:0 15px; text-align:center;">
                                                   <h1 style="color:#1e1e2d; font-weight:400; margin:0;font-size:20px;font-family:'Rubik',sans-serif;">Complaint Details</h1>
                                                   <span style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; 
                                                   width:100px;"></span>
                                               </td>
                                           </tr>
                                      
                                           <!-- Details Table -->   
                                           <div style="width:100%;height:30px;background:#e74c3c;display:flex;align-item:center;justify-content:center">
                                           <h6 style="margin-top:10px;color:#fff; font-family:'Rubik',sans-serif;margin-left:10px">
                                             Details of opposite party
                                           </h6>
                                        </div>
                                           <tr>
                                               <td>
                                                   <table cellpadding="0" cellspacing="0"
                                                       style="width: 100%; border: 1px solid #ededed">
                                                       <tbody>
                                                       
                                                           <tr>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; border-right: 1px solid #ededed; width: 35%; font-weight:700; color:#000;font-size:12px;">
                                                                   Company/Firm/Service Provider Name :</td>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; color: #455056; font-size:12px">
                                                                   ${yourProviderName}</td>
                                                           </tr>
                                                           <tr>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; border-right: 1px solid #ededed; width: 35%; font-weight:700; color:#000;font-size:12px">
                                                                   Email ID:</td>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; color: #455056; font-size:12px">
                                                                   ${yourEmail}</td>
                                                           </tr>
                                                           <tr>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; border-right: 1px solid #ededed; width: 35%; font-weight:700; color:#000;font-size:12px">
                                                                   Contact:</td>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; color: #455056; font-size:12px">
                                                                   ${yourContact}</td>
                                                           </tr>
                                                         
                                                           <tr>
                                                               <td
                                                                   style="padding: 10px;  border-bottom: 1px solid #ededed; border-right: 1px solid #ededed; width: 35%;font-weight:700; color:#000;font-size:12px">
                                                                   Name of concern person  :</td>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; color: #455056; font-size:12px">
                                                                  ${concern}</td>
                                                           </tr>
                                                           <tr>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; border-right: 1px solid #ededed; width: 35%;font-weight:700; color:#000;font-size:12px">
                                                                 Name of product:</td>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; color: #455056; font-size:12px ">
                                                                   ${productName}</td>
                                                           </tr>
                                                           <tr>
                                                               <td
                                                                   style="padding: 10px; border-right: 1px solid #ededed; width: 35%;font-weight:700;color:#000;font-size:12px">
                                                                 Address:</td>
                                                               <td style="padding: 10px; color: #455056; font-size:12px">${yourFullAddress} , ${yourCity} , ${yourState} , ${yourPincode}</td>
                                                           </tr>
                                                        
                                                        
                                                       </tbody>
                                                   </table>
                                                     <div style="width:100%;height:30px;background:#e74c3c;display:flex;align-item:center;justify-content:center">
                                                           <h6 style="margin-top:10px;color:#fff; font-family:'Rubik',sans-serif;margin-left:10px">
                                                          Have You Complained To Anyother Platform Earlier
                                                           </h6>
                                                         </div>
                                                    <table cellpadding="0" cellspacing="0"
                                                       style="width: 100%; border: 1px solid #ededed">
                                                       <tbody>
                                                           <tr>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; border-right: 1px solid #ededed; width: 35%; font-weight:600; color:#000;font-size:12px;">
                                                                   Have You Complained To Anyother Platform Earlier :</td>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; color: #455056; font-size:12px">
                                                                   ${haveYouComplaintToAnother}</td>
                                                           </tr>
                                                           <tr>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; border-right: 1px solid #ededed; width: 35%; font-weight:700; color:#000;font-size:12px">
                                                                   Name of Platform:</td>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; color: #455056; font-size:12px">
                                                                   ${haveYouComplaintToAnotherText}</td>
                                                           </tr>
                                                       </tbody>
                                                   </table>
                                                  <div style="width:100%;height:30px;background:#e74c3c;display:flex;align-item:center;justify-content:center">
                                                           <h6 style="margin-top:10px;color:#fff; font-family:'Rubik',sans-serif;margin-left:10px">
                                                         Resolution
                                                           </h6>
                                                         </div>
                                                      <table cellpadding="0" cellspacing="0"
                                                       style="width: 100%; border: 1px solid #ededed">
                                                       <tbody>
                                                           <tr>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; border-right: 1px solid #ededed; width: 35%; font-weight:600; color:#000;font-size:12px;">
                                                                  Resolution :</td>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; color: #455056; font-size:12px">
                                                                   ${resolutionValue.map(
                                                                     (item) => {
                                                                       return `
                                                                        <p>${item}</p>
                                                                        `;
                                                                     }
                                                                   )}
                                                               </td>
                                                           </tr>
                                                           <tr>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; border-right: 1px solid #ededed; width: 35%; font-weight:700; color:#000;font-size:12px">
                                                                   Name of Platform:</td>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; color: #455056; font-size:12px">
                                                                   ${productValue}</td>
                                                           </tr>
                                                       
                                                       </tbody>
                                                   </table>
                              
                                                  <div style="width:100%;height:30px;background:#e74c3c;display:flex;align-item:center;justify-content:center">
                                                           <h6 style="margin-top:10px;color:#fff; font-family:'Rubik',sans-serif;margin-left:10px">
                                                         Product/Service Name
                                                           </h6>
                                                         </div>
                                                      <table cellpadding="0" cellspacing="0"
                                                       style="width: 100%; border: 1px solid #ededed">
                                                       <tbody>
                                                      
                                                           <tr>
                                                             
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; color: #455056; font-size:12px">
                                                                   ${haveStartCourtActionText}</td>
                                                           </tr>
                                                         
                                                       </tbody>
                                                   </table>
                                                     <div style="width:100%;height:30px;background:#e74c3c;display:flex;align-item:center;justify-content:center">
                                                           <h6 style="margin-top:10px;color:#fff; font-family:'Rubik',sans-serif;margin-left:10px">
                                                      Product Or Service Value (INR)
                                                           </h6>
                                                         </div>
                                                    <table cellpadding="0" cellspacing="0"
                                                       style="width: 100%; border: 1px solid #ededed">
                                                       <tbody>
                                                      
                                                           <tr>
                                                             
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; color: #455056; font-size:12px">
                                                                  ${productValue}</td>
                                                           </tr>
                                                         
                                                       </tbody>
                                                   </table>
                                                    <div style="width:100%;height:30px;background:#e74c3c;display:flex;align-item:center;justify-content:center">
                                                           <h6 style="margin-top:10px;color:#fff; font-family:'Rubik',sans-serif;margin-left:10px">
                                                  How Would You Like Your Complaint To Get Solved?
                                                           </h6>
                                                         </div>
                                                       <table cellpadding="0" cellspacing="0"
                                                       style="width: 100%; border: 1px solid #ededed">
                                                       <tbody>
                                                      
                                                           <tr>
                                                             
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; color: #455056; font-size:12px">
                                                                 ${howYouLikeComplaintSolve}</td>
                                                           </tr>
                                                         
                                                       </tbody>
                                                   </table>
                                                   <div style="width:100%;height:30px;background:#e74c3c;display:flex;align-item:center;justify-content:center">
                                                           <h6 style="margin-top:10px;color:#fff; font-family:'Rubik',sans-serif;margin-left:10px">
                                                       When Did This Transaction / Problem Occur?
                                                           </h6>
                                                         </div>
                                                      <table cellpadding="0" cellspacing="0"
                                                       style="width: 100%; border: 1px solid #ededed">
                                                       <tbody>
                                                      
                                                           <tr>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; border-right: 1px solid #ededed; width: 35%; font-weight:600; color:#000;font-size:12px;">
                                                                  Date </td>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; color: #455056; font-size:12px">
                                                                   ${whenProblemOccurDate}</td>
                                                           </tr>
                                                           <tr>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; border-right: 1px solid #ededed; width: 35%; font-weight:700; color:#000;font-size:12px">
                                                                 Time</td>
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; color: #455056; font-size:12px">
                                                                   ${whenProblemOccurTime}</td>
                                                           </tr>
                                                       
                                                       </tbody>
                                                   </table>
                                                     <div style="width:100%;height:30px;background:#e74c3c;display:flex;align-item:center;justify-content:center">
                                                           <h6 style="margin-top:10px;color:#fff; font-family:'Rubik',sans-serif;margin-left:10px">
                                                        Select A Type Which Is Most Relevant To Your Complaint
                                                           </h6>
                                                         </div>
                                                      <table cellpadding="0" cellspacing="0"
                                                       style="width: 100%; border: 1px solid #ededed">
                                                       <tbody>
                                                      
                                                           <tr>
                                                             
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; color: #455056; font-size:12px">
                                                                   ${complaintType}</td>
                                                           </tr>
                                                         
                                                       </tbody>
                                                   </table>
                                                   <div style="width:100%;height:30px;background:#e74c3c;display:flex;align-item:center;justify-content:center">
                                                           <h6 style="margin-top:10px;color:#fff; font-family:'Rubik',sans-serif;margin-left:10px">
                                                     Please Describe Your Complaint In Detail
                                                           </h6>
                                                         </div>
                                                      <table cellpadding="0" cellspacing="0"
                                                       style="width: 100%; border: 1px solid #ededed">
                                                       <tbody>
                                                      
                                                           <tr>
                                                             
                                                               <td
                                                                   style="padding: 10px; border-bottom: 1px solid #ededed; color: #455056; font-size:12px">
                                                                   ${complaintDescription}</td>
                                                           </tr>
                                                         
                                                       </tbody>
                                                   </table>
                                                   <div style="width:100%;height:30px;background:#e74c3c;display:flex;align-item:center;justify-content:center">
                                                           <h6 style="margin-top:10px;color:#fff; font-family:'Rubik',sans-serif;margin-left:10px">
                                                           Proof
                                                           </h6>
                                                         </div>
                                                      <table cellpadding="0" cellspacing="0"
                                                       style="width: 100%; border: 1px solid #ededed">
                                                       <tbody>
                                                      
                                                           <tr>
                                                             
                                                           <td
                                                           style="padding: 10px; border-bottom: 1px solid #ededed; color: #455056; font-size:12px">
                                                         <div style="display:flex;align-item:center;justify-content:center">
                                                         ${
                                                           images &&
                                                           images.map(
                                                             (image) => {
                                                               return `
                                                              <img src=${`https://cro-org-bucket.s3.ap-south-1.amazonaws.com/${image}`} style="width:50%" />
                                                              `;
                                                             }
                                                           )
                                                         }
                                                           </div>
                                                     
                                                     </td>
                                                           </tr>
                                                         
                                                         <tr>
                                                           <td style="display:flex;align-item:center;justify-content:center">
                                                             <button style="padding:10px;width:150px;background:#e74c3c;border:1px solid #e74c3c;border-radius:10px"><a href="https://www.thecro.org/" style="text-decoration:none;color:#fff">  More Info </a>  </button>
                                                           </td>
                                                         </tr>
                                                         
                                                       </tbody>
                                                   </table>
                                               </td>
                                           </tr>
                                         
                                           <tr>
                                               <td style="height:40px;">&nbsp;</td>
                                           </tr>
                                       </table>
                                   </td>
                               </tr>
                               <tr>
                                   <td style="height:20px;">&nbsp;</td>
                               </tr>
                               <tr>
                                   <td style="text-align:center;">
                                           <p style="font-size:14px; color:#455056bd; line-height:18px; margin:0 0 0;">&copy; <strong>https://www.thecro.org/</strong></p>
                                   </td>
                               </tr>
                           </table>
                       </td>
                   </tr>
               </table>
           </body>
           </html>`;
            createMail({
              variables: {
                email: `${yourEmail}`,
                htmlContext: `${EmailhtmlTemplate}`,
              },
            });
          })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Your complaint has been registered successfully.",
            }).then((result) => {
              if (result.isConfirmed) {
                navigation("/complaint/userDashboard/pendingComplaintsUser");
              }
            });
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [validated, setValidated] = useState(false);

  const handleCheckboxChange = (event) => {
    sethaveYouComplaintToAnother(event.target.value === "yes");
  };

  // if (!userToken) {
  //   // navigation('/userLoginComplain')
  //   return <UserLoginComplaint />;
  // }

  return (
    <>
      <Navbar />
      <Container style={{ marginTop: 150 }}>
        <Row>
          <Col md={8} style={{ marginTop: 100, marginBottom: 150 }}>
            <h1
              style={{
                fontSize: 30,
                fontFamily: "poppins",
                fontWeight: "bold",
                color: "#ff5748",
              }}
            >
              Got a complaint?
            </h1>
            <p
              style={{
                fontSize: 15,
                fontFamily: "poppins",
                color: "#34495e",
                fontWeight: "bold",
              }}
            >
              If your complaint has not been solved or you are not satisfied
              with the response, we may be able to help. If you have contacted
              the company concerned and they have not solved the problem or you
              are not satisfied with their response, we may be able to help. We
              are here to act on your behalf and investigate your complaint.
              File your complaint below or contact our Consumer Support team on
              +91 9977-252-252
            </p>
          </Col>
          <Col md={4} className="d-none d-lg-block">
            <Lottie
              options={defaultOptions}
              height={500}
              width={500}
              className="mx-auto d-block"
            />
          </Col>
        </Row>
        <h1
          className="text-center"
          style={{
            fontWeight: "800",
            fontSize: "2rem",
            color: "#2c3e50",
            marginTop: "-30px",
          }}
        >
          Complaint Form
        </h1>
      </Container>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <h1 className="complaintText">Complainer Details</h1>
      <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Form.Label
                          style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            fontFamily: "poppins",
                          }}
                        >
                          Full Name
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          style={{ fontSize: 14, fontFamily: "poppins" }}
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Form.Label
                          style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            fontFamily: "poppins",
                          }}
                        >
                          Email
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          style={{ fontSize: 14, fontFamily: "poppins" }}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Form.Label
                          style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            fontFamily: "poppins",
                          }}
                        >
                          Phone Number
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          style={{ fontSize: 14, fontFamily: "poppins" }}
                          value={phone_number}
                          onChange={(e) => setPhone_number(e.target.value)}
                        />
                      </Form.Group>
                    </Row>
                    <h1 className="complaintText">
                        Details of opposite party
                      </h1>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Form.Label
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: "poppins",
                            }}
                          >
                            Company/Firm/Service Provider Name
                          </Form.Label>
                          <Form.Control
                            onChange={(e) =>
                              setyourProviderName(e.target.value)
                            }
                            required
                            type="text"
                            value={yourProviderName}
                            style={{ fontSize: 14, fontFamily: "poppins" }}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Form.Label
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: "poppins",
                            }}
                          >
                            Email
                          </Form.Label>
                          <Form.Control
                            onChange={(e) => setyourEmail(e.target.value)}
                            required
                            type="text"
                            value={yourEmail}
                            style={{ fontSize: 14, fontFamily: "poppins" }}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Form.Label
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: "poppins",
                            }}
                          >
                            Contact
                          </Form.Label>
                          <Form.Control
                            onChange={(e) => setyourContact(e.target.value)}
                            required
                            type="number"
                            value={yourContact}
                            style={{ fontSize: 14, fontFamily: "poppins" }}
                          />
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Form.Label
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: "poppins",
                              color: "#34495e",
                            }}
                          >
                            Name of concern person, if any
                          </Form.Label>
                          <Form.Control
                            onChange={(e) => setconcern(e.target.value)}
                            required
                            type="text"
                            value={concern}
                            style={{ fontSize: 14, fontFamily: "poppins" }}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Form.Label
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: "poppins",
                            }}
                          >
                            Name of product
                          </Form.Label>
                          <Form.Control
                            onChange={(e) => setproductName(e.target.value)}
                            required
                            type="text"
                            value={productName}
                            style={{ fontSize: 14, fontFamily: "poppins" }}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Form.Label
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: "poppins",
                            }}
                          >
                            Address
                          </Form.Label>
                          <Form.Control
                            onChange={(e) => setyourFullAddress(e.target.value)}
                            required
                            type="text"
                            value={yourFullAddress}
                            style={{ fontSize: 14, fontFamily: "poppins" }}
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Form.Label
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: "poppins",
                            }}
                          >
                            City
                          </Form.Label>
                          <Form.Control
                            onChange={(e) => setyourCity(e.target.value)}
                            required
                            type="text"
                            value={yourCity}
                            style={{ fontSize: 14, fontFamily: "poppins" }}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Form.Label
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: "poppins",
                            }}
                          >
                            State
                          </Form.Label>
                          <Form.Control
                            onChange={(e) => setyourState(e.target.value)}
                            required
                            type="text"
                            value={yourState}
                            style={{ fontSize: 14, fontFamily: "poppins" }}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Form.Label
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: "poppins",
                            }}
                          >
                            Pincode
                          </Form.Label>
                          <Form.Control
                            onChange={(e) => setyourPincode(e.target.value)}
                            required
                            type="number"
                            value={yourPincode}
                            style={{ fontSize: 14, fontFamily: "poppins" }}
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mt-4 mb-4">
                    <Col md={6} className="pt-3 pb-3">
                      <h1 className="mb-3 complaintText">
                        Have You Complained To Anyother Platform Earlier Yes Or
                        No (If Yes Name Of Platform)
                      </h1>
                      <Row style={{ marginTop: 30 }}>
                        <Col md={2}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="yes"
                              id="flexCheckDefault"
                              checked={haveYouComplaintToAnother}
                              onChange={handleCheckboxChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                              style={{marginTop:3,marginLeft:3}}
                            >
                              Yes
                            </label>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="no"
                              id="flexCheckChecked"
                              checked={!haveYouComplaintToAnother}
                              onChange={handleCheckboxChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                              style={{marginTop:3,marginLeft:3}}
                            >
                              No
                            </label>
                          </div>
                        </Col>
                        <Col md={8}>
                          <Form.Group
                            controlId="validationCustom03"
                            style={{ background: "" }}
                          >
                            <Form.Control
                              onChange={(e) =>
                                sethaveYouComplaintToAnotherText(e.target.value)
                              }
                              style={{
                                fontSize: "1rem",
                                border: "none",
                                background: "transparent",
                                borderBottom: "2px solid gray",
                                borderRadius: "0",
                                paddingLeft: "0",
                                marginTop: "-1rem",
                                fontFamily: "poppins",
                              }}
                              type="text"
                              placeholder="Name of Platform"
                              required
                              value={haveYouComplaintToAnotherText}
                              disabled={!haveYouComplaintToAnother}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="pt-3 pb-3">
                      <h1 className="mb-3 complaintText">Resolution</h1>
                      <Row>
                        <Col md={4}>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Refund"
                              value="Refund"
                              onChange={handleChangeforResolution}
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label ms-2"
                              for="flexCheckDefault"
                              style={{marginTop:3,marginLeft:3}}
                            >
                              Refund
                            </label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Replacement"
                              value="Replacement"
                              onChange={handleChangeforResolution}
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                              style={{marginTop:3,marginLeft:3}}
                            >
                              Replacement
                            </label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Apology"
                              value="Apology"
                              onChange={handleChangeforResolution}
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                              style={{marginTop:3,marginLeft:3}}
                            >
                              Apology
                            </label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Compensation"
                              value="Compensation"
                              onChange={handleChangeforResolution}
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                              style={{marginTop:3,marginLeft:3}}
                            >
                              Compensation
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-4 mb-4">
                    <Col md={6} className="pt-3 pb-3">
                      <h1 className="mb-3 complaintText">
                        Product/Service Name
                      </h1>

                      <Row style={{ marginTop: 30 }}>
                        <Col md={12}>
                          <Form.Group
                            controlId="validationCustom03"
                            style={{ background: "" }}
                          >
                            <Form.Control
                              onChange={(e) =>
                                sethaveStartCourtActionText(e.target.value)
                              }
                              style={{
                                fontSize: "1rem",
                                border: "none",
                                background: "transparent",
                                borderBottom: "2px solid gray",
                                borderRadius: "0",
                                paddingLeft: "0",
                                marginTop: "-1rem",
                                fontFamily: "poppins",
                              }}
                              type="text"
                              placeholder="Product/Service"
                              value={haveStartCourtActionText}
                              required
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="pt-3 pb-3">
                      <h1 className="mb-3 complaintText">
                        Product Or Service Value (INR)
                      </h1>
                      <div className="col-sm-12">
                        <Form.Group
                          controlId="validationCustom03"
                          style={{ background: "" }}
                        >
                          <Form.Control
                            onChange={(e) => setproductValue(e.target.value)}
                            style={{
                              fontSize: "1rem",
                              border: "none",
                              background: "transparent",
                              borderBottom: "2px solid gray",
                              borderRadius: "0",
                              paddingLeft: "0",
                              marginTop: "1rem",
                              fontFamily: "poppins",
                            }}
                            type="number"
                            value={productValue}
                            placeholder="Amount in INR"
                            required
                          />
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-4 mb-4">
                    <Col md={6} className="pt-3 pb-3">
                      <h1 className="mb-3 complaintText">
                        Attach Files (Proof Of Evidences)
                      </h1>
                      {images.length !== 0 ? (
                        <Table bordered style={{ fontSize: 12 }}>
                          <thead>
                            <tr>
                              <th>S.NO.</th>
                              <th>image</th>
                              <th>status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {images &&
                              images.map((image, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{image}</td>
                                  <td style={{ color: "green" }}>uploaded</td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      ) : (
                        ""
                      )}

                      <Form.Group
                        as={Col}
                        md="12"
                        className="d-flex align-items-center"
                      >
                        <Form.Control
                          ref={fileInputRef}
                          style={{
                            fontSize: "1rem",
                            border: "none",
                            background: "transparent",
                            borderBottom: "2px solid gray",
                            borderRadius: "0",
                            paddingLeft: "0",
                            marginTop: 20,
                          }}
                          type="file"
                          onChange={handleImageChange}
                        />
                        {imageLoading ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          <Button
                            style={{
                              padding: "5px 10px",
                              background: "#FF5748",
                              border: "none",
                              marginLeft: "10px",
                            }}
                            variant="danger"
                            onClick={handleUploadImage}
                          >
                            Upload
                          </Button>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} className="pt-3 pb-3">
                      <h1 className="mb-3 complaintText">
                        How Would You Like Your Complaint To Get Solved?
                      </h1>
                      <div className="col-sm-12">
                        <textarea
                          style={{
                            border: "2px solid gray",
                            padding: "10px",
                            height: 100,
                            fontFamily: "poppins",
                          }}
                          name=""
                          placeholder="Describe your Approach"
                          onChange={(e) =>
                            sethowYouLikeComplaintSolve(e.target.value)
                          }
                          value={howYouLikeComplaintSolve}
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-4 mb-4">
                    <Col md={12} className="pt-3 pb-3">
                      <h1 className="mb-3 complaintText">
                        When Did This Transaction / Problem Occur?
                      </h1>
                      <div className="col">
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            md="6"
                            className="mt-sm-4 mt-md-0"
                            controlId="validationCustom03"
                          >
                            <Form.Label>Date</Form.Label>
                            <div style={{ position: "relative" }}>
                              <Form.Control
                                onChange={(e) =>
                                  setwhenProblemOccurDate(e.target.value)
                                }
                                style={{
                                  fontSize: "1rem",
                                  border: "none",
                                  background: "transparent",
                                  borderBottom: "2px solid gray",
                                  borderRadius: "0",
                                  paddingLeft: "0",
                                }}
                                type="date"
                                required
                                value={whenProblemOccurDate}
                              />
                              <i
                                className="bi bi-calendar"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  right: "0.5rem",
                                  transform: "translateY(-50%)",
                                }}
                              ></i>
                            </div>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid date.
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            className="mt-sm-4 mt-md-0"
                            controlId="validationCustom03"
                          >
                            <Form.Label>Time</Form.Label>
                            <div style={{ position: "relative" }}>
                              <Form.Control
                                onChange={(e) =>
                                  setwhenProblemOccurTime(e.target.value)
                                }
                                style={{
                                  fontSize: "1rem",
                                  border: "none",
                                  background: "transparent",
                                  borderBottom: "2px solid gray",
                                  borderRadius: "0",
                                  paddingLeft: "0",
                                }}
                                type="time"
                                required
                                value={whenProblemOccurTime}
                              />
                              <i
                                className="bi bi-clock"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  right: "0.5rem",
                                  transform: "translateY(-50%)",
                                }}
                              ></i>
                            </div>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid time.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      </div>
                    </Col> 
                    {/* <Col md={6} className='pt-3 pb-3'>
                                            <h1 className='mb-3 complaintText'>Did You Sign Any Agreement?</h1>
                                            <div className="col-sm-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="yes" id="flexCheckDefault" checked={didSignAggrement} onChange={handleCheckboxChange2} />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">Yes</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="no" id="flexCheckChecked" checked={!didSignAggrement} onChange={handleCheckboxChange2} />
                                                    <label className="form-check-label" htmlFor="flexCheckChecked">No</label>
                                                </div>
                                            </div>
                                        </Col> */}
                   </Row>
                   <Row className="mt-4 mb-4">
                    <Col md={6} className="pt-3 pb-3">
                      <h1 className="mb-3 complaintText">
                        Select A Type Which Is Most Relevant To Your Complaint
                      </h1>
                      <Form.Select
                        required
                        onChange={(e) => setcomplaintType(e.target.value)}
                        value={complaintType}
                        aria-label="Default select example"
                        style={{ fontFamily: "poppins" }}
                      >
                        <option disabled selected>
                          Open this select menu
                        </option>
                        <option value="Airline">Airline complaints</option>
                        <option value="Railway">Railway complaints</option>
                        <option value="Banking">Banking complaints</option>
                        <option value="Postal">Postal complaints</option>
                        <option value="Medical">Medical complaints</option>
                        <option value="Travel">Travel complaints</option>
                        <option value="Telecom">Telecom complaints</option>
                        <option value="E-Commerce">
                          E-Commerce complaints
                        </option>
                        <option value="Insurance">Insurance complaints</option>
                        <option value="Real-Estate">
                          Real-Estate complaints
                        </option>
                        <option value="Home Appliances">
                          Home appliances complaints
                        </option>
                        <option value="Electricity">
                          Electricity complaints
                        </option>
                        <option value="Government Service">
                          Government service complaints
                        </option>
                        <option value="LPG/Petroleum">
                          LPG/Petroleum complaints
                        </option>
                        <option value="Food Safety">
                          Food safety complaints
                        </option>
                        <option value="Mobile/Laptop">
                          Mobile/laptop complaints
                        </option>
                        <option value="Automobiles">
                          Automobiles complaints
                        </option>
                        <option value="Education">Education complaints</option>
                        <option value="Internet Service Provider">
                          Internet service providers complaints
                        </option>
                        <option value="D2H">D2H complaints</option>
                        <option value="Others">Others</option>
                      </Form.Select>
                    </Col>
                    <Col md={6} className="pt-3 pb-3">
                      <h1 className="mb-3 complaintText">
                        Please Describe Your Complaint In Detail
                      </h1>
                      <div className="col-sm-12">
                        <textarea
                          style={{
                            border: "2px solid gray",
                            padding: "10px",
                            height: 100,
                            fontFamily: "poppins",
                          }}
                          name=""
                          placeholder="Describe your issue"
                          id=""
                          required
                          onChange={(e) =>
                            setcomplaintDescription(e.target.value)
                          }
                          value={complaintDescription}
                          cols="30"
                          rows="10"
                        ></textarea>
                      </div>
                    </Col>
                  </Row>   
                  <Row>
                  <h5 className="section-heading complaintText2">
                        Authorizing CRO
                      </h5>
                      <div
                        className="d-flex align-center justify-content-between"
                        style={{ background: "#F3F4F6", padding: "2rem" }}
                      >
                        <div className="col-sm-12">
                          <div
                            style={{
                              background: "#fff",
                              padding: "2rem",
                              borderRadius: "15px",
                              height: "200px",
                              overflow: "hidden",
                              overflowY: "auto",
                            }}
                          >
                            <h4
                              className="text-center mb-4"
                              style={{ fontWeight: "800", fontSize: "1.1rem" }}
                            >
                              Authorization to CRO- Consumer Rights
                              Organization/ Upbhokta Adhikar Sangthan : -
                            </h4>
                            <p
                              style={{
                                lineHeight: "1.4",
                                color: "rgb(94, 94, 94)",
                                marginBottom: "2rem",
                              }}
                            >
                              I do hereby Authorize and appoint Consumer Rights
                              Organization/ Upbhokta Adhikar Sangthan
                              (hereinafter referred and called as “CRO”) for
                              following acts on my behalf. To act, appear and
                              plead in the above-noted consumer case in any
                              Court or in any other Forum/Tribunal/commission in
                              which the same may be tried or heard and also in
                              the appellate Court including state
                              commission/national commission subject to payment
                              of fees separately for each Court by me/us.
                            </p>
                            <p
                              style={{
                                lineHeight: "1.4",
                                color: "rgb(94, 94, 94)",
                                marginBottom: "2rem",
                              }}
                            >
                              To sign, file, verify and present pleadings,
                              appeals, cross-objections or petitions for
                              executions review revision, withdrawal, compromise
                              or other petitions or affidavits or other
                              documents as may be deemed necessary or proper for
                              the purpose of the said case in all its stages
                              subject to payment of fees for each stage. To file
                              and take back documents, to admit and/or deny the
                              documents of the opposite party.
                            </p>
                            <p
                              style={{
                                lineHeight: "1.4",
                                color: "rgb(94, 94, 94)",
                                marginBottom: "2rem",
                              }}
                            >
                              To withdraw or compromise the said case or submit
                              to arbitration any differences or disputes that
                              may arise touching or in any manner relating to
                              the said case. To take execution proceedings.
                            </p>
                            <p
                              style={{
                                lineHeight: "1.4",
                                color: "rgb(94, 94, 94)",
                                marginBottom: "2rem",
                              }}
                            >
                              To deposit, draw and receive monthly cheques, cash
                              and grant receipts thereof and to do all other
                              acts and things which may be necessary to be done
                              for the progress and in the course of the
                              prosecution of the said case.
                            </p>
                            <p
                              style={{
                                lineHeight: "1.4",
                                color: "rgb(94, 94, 94)",
                                marginBottom: "2rem",
                              }}
                            >
                              To appoint and instruct any other Legal
                              Practitioner authorizing him to exercise the power
                              and authority hereby conferred upon the Advocate
                              whenever he may think fit to do so and to sign the
                              power of attorney on our behalf
                            </p>
                            <p
                              style={{
                                lineHeight: "1.4",
                                color: "rgb(94, 94, 94)",
                                marginBottom: "2rem",
                              }}
                            >
                              And I/We the undersigned do hereby agree to
                              rectify and confirm all acts done by the CRO and
                              Advocate appointed by CRO or his substitute in the
                              matter as my/our own acts, as if done by me/us to
                              all intents and purposes. And I/We undertake that
                              I/We or my/our duly authorized agent would appear
                              in Court on all hearings and will inform the CRO
                              for appearance when the case is called. And I/We
                              the undersigned do hereby agree not to hold the
                              CRO/advocate or his substitute responsible for the
                              result of the said case
                            </p>
                            <p
                              style={{
                                lineHeight: "1.4",
                                color: "rgb(94, 94, 94)",
                                marginBottom: "2rem",
                              }}
                            >
                              The adjournment costs and Legal expenses whenever
                              ordered by the Court shall be of the CRO/Advocate
                              Appointed by CRO, which he shall receive and
                              retain for himself. And I/We the undersigned to
                              hereby agree that in the event of the whole or
                              part of the fee agreed by me/us to be paid to the
                              CRO/advocate remaining unpaid he shall be entitled
                              to withdraw from the prosecution of the said case
                              until the same is paid up. The fee settled is only
                              for the above case and above Court. I/we hereby
                              agree that once fee is paid, I/We will not be
                              entitled for the refund of the same in any case
                              whatsoever and if the case prolongs for more than
                              3 years the original fee shall be paid again by
                              me/us. I hereby fully agreed to give my complaint
                              details to publish on social media on my behalf by
                              the CRO
                            </p>
                            <p
                              style={{
                                lineHeight: "1.4",
                                color: "rgb(94, 94, 94)",
                                marginBottom: "2rem",
                              }}
                            >
                              I am giving my concern to the CRO to publish my
                              details in survey report, media report, on
                              websites etc.,
                            </p>
                            <p
                              style={{
                                lineHeight: "1.4",
                                color: "rgb(94, 94, 94)",
                                marginBottom: "2rem",
                              }}
                            >
                              Declaration by me :- I the above named complainant
                              hereby declare and undertake that all the details
                              and documents furnished here are true and correct
                              to the best of my knowledge and I rely on it
                            </p>
                          </div>
                          <div className="form-check"  style={{marginTop:10,display:"flex"}}>
                            
                              <input
                              className="form-check-input"
                              type="checkbox"
                              value="no"
                              id="flexCheckChecked"
                             
                            />
                              
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                              style={{marginTop:3,marginLeft:5}}
                            >
                             I Authorize CRO Consumer Rights Organization to send notice and fight case on behalf of myself
                            </label>
                          </div>
                        
                        </div>

                      </div>
                      <h5
                        className="section-heading complaintText2"
                        style={{ marginTop: 50 }}
                      >
                        Declaration
                      </h5>
                      <div
                        className="d-flex align-center justify-content-between"
                        style={{ background: "#F3F4F6", padding: "2rem" }}
                      >
                        <div className="col-sm-12">
                        <div className="form-check"  style={{marginTop:10,display:"flex"}}>
                            
                            <input
                            className="form-check-input"
                            type="checkbox"
                            value="no"
                            id="flexCheckChecked"
                           
                          />
                            
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                            style={{marginTop:3,marginLeft:5}}
                          >
                           I declare that all details filled and files attached in the complaint form are true and correct
                          </label>
                        </div>
                      
                       
                        </div>
                      </div>
                      <h5
                        className="section-heading complaintText2"
                        style={{ marginTop: 50 }}
                      >
                        Consent And Verification
                      </h5>
                      <div
                        className="d-flex align-center justify-content-between"
                        style={{ background: "#F3F4F6", padding: "2rem" }}
                      >
                        <div className="col-sm-12">
                          <p
                            style={{
                              lineHeight: "1.4",
                              color: "rgb(94, 94, 94)",
                            }}
                          >
                            I affirm, under the penalties for perjury, that the
                            foregoing representations, and those in all
                            attachments, are true. The information i have
                            provided in this complaint form is based upon my
                            personal knowledge. I consent to the Consumer Rights
                            Organisation Obtaining or releasing any information
                            in furtherance of the disposition of this complaint.
                            I understand if i provide any wrong information than
                            i will be liable for legal proceedings against me
                          </p>
                          <div className="form-check"  style={{marginTop:10,display:"flex"}}>
                            
                            <input
                            className="form-check-input"
                            type="checkbox"
                            value="no"
                            id="flexCheckChecked"
                           
                          />
                            
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                            style={{marginTop:3,marginLeft:5}}
                          >
                          I declare that all details filled and files attached in the complaint form are true and correct
                          </label>
                        </div>
                         
                        </div>
                      </div>
                    </Row>
                    {loading ? (
            <Spinner
              animation="border"
              role="status"
              className="mx-auto d-block"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <Button
              type="submit"
              className="mx-auto d-block mt-4 complaintSubmitBtn"
              style={{fontFamily:"Poppins, serif"}}
            >
              Submit form
            </Button>
          )}
      </Form>

      {/* <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  borderRadius: 10,
                }}
              >
                <Card.Body>
                  <Row>
                    <Col md={12}>
                      <h1 className="complaintText">Complainer Details</h1>
                    </Col>
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Form.Label
                          style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            fontFamily: "poppins",
                          }}
                        >
                          Full Name
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          style={{ fontSize: 14, fontFamily: "poppins" }}
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Form.Label
                          style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            fontFamily: "poppins",
                          }}
                        >
                          Email
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          style={{ fontSize: 14, fontFamily: "poppins" }}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Form.Label
                          style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            fontFamily: "poppins",
                          }}
                        >
                          Phone Number
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          style={{ fontSize: 14, fontFamily: "poppins" }}
                          value={phone_number}
                          onChange={(e) => setPhone_number(e.target.value)}
                        />
                      </Form.Group>
                    </Row>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <h1 className="complaintText">
                        Details of opposite party
                      </h1>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Form.Label
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: "poppins",
                            }}
                          >
                            Company/Firm/Service Provider Name
                          </Form.Label>
                          <Form.Control
                            onChange={(e) =>
                              setyourProviderName(e.target.value)
                            }
                            required
                            type="text"
                            value={yourProviderName}
                            style={{ fontSize: 14, fontFamily: "poppins" }}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Form.Label
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: "poppins",
                            }}
                          >
                            Email
                          </Form.Label>
                          <Form.Control
                            onChange={(e) => setyourEmail(e.target.value)}
                            required
                            type="text"
                            value={yourEmail}
                            style={{ fontSize: 14, fontFamily: "poppins" }}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Form.Label
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: "poppins",
                            }}
                          >
                            Contact
                          </Form.Label>
                          <Form.Control
                            onChange={(e) => setyourContact(e.target.value)}
                            required
                            type="number"
                            value={yourContact}
                            style={{ fontSize: 14, fontFamily: "poppins" }}
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Form.Label
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: "poppins",
                              color: "#34495e",
                            }}
                          >
                            Name of concern person, if any
                          </Form.Label>
                          <Form.Control
                            onChange={(e) => setconcern(e.target.value)}
                            required
                            type="text"
                            value={concern}
                            style={{ fontSize: 14, fontFamily: "poppins" }}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Form.Label
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: "poppins",
                            }}
                          >
                            Name of product
                          </Form.Label>
                          <Form.Control
                            onChange={(e) => setproductName(e.target.value)}
                            required
                            type="text"
                            value={productName}
                            style={{ fontSize: 14, fontFamily: "poppins" }}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Form.Label
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: "poppins",
                            }}
                          >
                            Address
                          </Form.Label>
                          <Form.Control
                            onChange={(e) => setyourFullAddress(e.target.value)}
                            required
                            type="text"
                            value={yourFullAddress}
                            style={{ fontSize: 14, fontFamily: "poppins" }}
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Form.Label
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: "poppins",
                            }}
                          >
                            City
                          </Form.Label>
                          <Form.Control
                            onChange={(e) => setyourCity(e.target.value)}
                            required
                            type="text"
                            value={yourCity}
                            style={{ fontSize: 14, fontFamily: "poppins" }}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Form.Label
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: "poppins",
                            }}
                          >
                            State
                          </Form.Label>
                          <Form.Control
                            onChange={(e) => setyourState(e.target.value)}
                            required
                            type="text"
                            value={yourState}
                            style={{ fontSize: 14, fontFamily: "poppins" }}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Form.Label
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: "poppins",
                            }}
                          >
                            Pincode
                          </Form.Label>
                          <Form.Control
                            onChange={(e) => setyourPincode(e.target.value)}
                            required
                            type="number"
                            value={yourPincode}
                            style={{ fontSize: 14, fontFamily: "poppins" }}
                          />
                        </Form.Group>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="mt-4 mb-4">
                    <Col md={6} className="pt-3 pb-3">
                      <h1 className="mb-3 complaintText">
                        Have You Complained To Anyother Platform Earlier Yes Or
                        No (If Yes Name Of Platform)
                      </h1>
                      <Row style={{ marginTop: 30 }}>
                        <Col md={2}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="yes"
                              id="flexCheckDefault"
                              checked={haveYouComplaintToAnother}
                              onChange={handleCheckboxChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Yes
                            </label>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="no"
                              id="flexCheckChecked"
                              checked={!haveYouComplaintToAnother}
                              onChange={handleCheckboxChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              No
                            </label>
                          </div>
                        </Col>
                        <Col md={8}>
                          <Form.Group
                            controlId="validationCustom03"
                            style={{ background: "" }}
                          >
                            <Form.Control
                              onChange={(e) =>
                                sethaveYouComplaintToAnotherText(e.target.value)
                              }
                              style={{
                                fontSize: "1rem",
                                border: "none",
                                background: "transparent",
                                borderBottom: "2px solid gray",
                                borderRadius: "0",
                                paddingLeft: "0",
                                marginTop: "-1rem",
                                fontFamily: "poppins",
                              }}
                              type="text"
                              placeholder="Name of Platform"
                              required
                              value={haveYouComplaintToAnotherText}
                              disabled={!haveYouComplaintToAnother}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="pt-3 pb-3">
                      <h1 className="mb-3 complaintText">Resolution</h1>
                      <Row>
                        <Col>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Refund"
                              value="Refund"
                              onChange={handleChangeforResolution}
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label ms-2"
                              for="flexCheckDefault"
                            >
                              Refund
                            </label>
                          </div>
                        </Col>
                        <Col>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Replacement"
                              value="Replacement"
                              onChange={handleChangeforResolution}
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              Replacement
                            </label>
                          </div>
                        </Col>
                        <Col>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Apology"
                              value="Apology"
                              onChange={handleChangeforResolution}
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              Apology
                            </label>
                          </div>
                        </Col>
                        <Col>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Compensation"
                              value="Compensation"
                              onChange={handleChangeforResolution}
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              Compensation
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="mt-4 mb-4">
                    <Col md={6} className="pt-3 pb-3">
                      <h1 className="mb-3 complaintText">
                        Product/Service Name
                      </h1>

                      <Row style={{ marginTop: 30 }}>
                        <Col md={12}>
                          <Form.Group
                            controlId="validationCustom03"
                            style={{ background: "" }}
                          >
                            <Form.Control
                              onChange={(e) =>
                                sethaveStartCourtActionText(e.target.value)
                              }
                              style={{
                                fontSize: "1rem",
                                border: "none",
                                background: "transparent",
                                borderBottom: "2px solid gray",
                                borderRadius: "0",
                                paddingLeft: "0",
                                marginTop: "-1rem",
                                fontFamily: "poppins",
                              }}
                              type="text"
                              placeholder="Product/Service"
                              value={haveStartCourtActionText}
                              required
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="pt-3 pb-3">
                      <h1 className="mb-3 complaintText">
                        Product Or Service Value (INR)
                      </h1>
                      <div className="col-sm-12">
                        <Form.Group
                          controlId="validationCustom03"
                          style={{ background: "" }}
                        >
                          <Form.Control
                            onChange={(e) => setproductValue(e.target.value)}
                            style={{
                              fontSize: "1rem",
                              border: "none",
                              background: "transparent",
                              borderBottom: "2px solid gray",
                              borderRadius: "0",
                              paddingLeft: "0",
                              marginTop: "1rem",
                              fontFamily: "poppins",
                            }}
                            type="number"
                            value={productValue}
                            placeholder="Amount in INR"
                            required
                          />
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-4 mb-4">
                    <Col md={6} className="pt-3 pb-3">
                      <h1 className="mb-3 complaintText">
                        Attach Files (Proof Of Evidences)
                      </h1>
                      {images.length !== 0 ? (
                        <Table bordered style={{ fontSize: 12 }}>
                          <thead>
                            <tr>
                              <th>S.NO.</th>
                              <th>image</th>
                              <th>status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {images &&
                              images.map((image, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{image}</td>
                                  <td style={{ color: "green" }}>uploaded</td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      ) : (
                        ""
                      )}

                      <Form.Group
                        as={Col}
                        md="12"
                        className="d-flex align-items-center"
                      >
                        <Form.Control
                          ref={fileInputRef}
                          style={{
                            fontSize: "1rem",
                            border: "none",
                            background: "transparent",
                            borderBottom: "2px solid gray",
                            borderRadius: "0",
                            paddingLeft: "0",
                            marginTop: 20,
                          }}
                          type="file"
                          onChange={handleImageChange}
                        />
                        {imageLoading ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          <Button
                            style={{
                              padding: "5px 10px",
                              background: "#FF5748",
                              border: "none",
                              marginLeft: "10px",
                            }}
                            variant="danger"
                            onClick={handleUploadImage}
                          >
                            Upload
                          </Button>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} className="pt-3 pb-3">
                      <h1 className="mb-3 complaintText">
                        How Would You Like Your Complaint To Get Solved?
                      </h1>
                      <div className="col-sm-12">
                        <textarea
                          style={{
                            border: "2px solid gray",
                            padding: "10px",
                            height: 100,
                            fontFamily: "poppins",
                          }}
                          name=""
                          placeholder="Describe your Approach"
                          onChange={(e) =>
                            sethowYouLikeComplaintSolve(e.target.value)
                          }
                          value={howYouLikeComplaintSolve}
                          required
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-4 mb-4">
                    <Col md={12} className="pt-3 pb-3">
                      <h1 className="mb-3 complaintText">
                        When Did This Transaction / Problem Occur?
                      </h1>
                      <div className="col">
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            md="6"
                            className="mt-sm-4 mt-md-0"
                            controlId="validationCustom03"
                          >
                            <Form.Label>Date</Form.Label>
                            <div style={{ position: "relative" }}>
                              <Form.Control
                                onChange={(e) =>
                                  setwhenProblemOccurDate(e.target.value)
                                }
                                style={{
                                  fontSize: "1rem",
                                  border: "none",
                                  background: "transparent",
                                  borderBottom: "2px solid gray",
                                  borderRadius: "0",
                                  paddingLeft: "0",
                                }}
                                type="date"
                                required
                                value={whenProblemOccurDate}
                              />
                              <i
                                className="bi bi-calendar"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  right: "0.5rem",
                                  transform: "translateY(-50%)",
                                }}
                              ></i>
                            </div>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid date.
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            className="mt-sm-4 mt-md-0"
                            controlId="validationCustom03"
                          >
                            <Form.Label>Time</Form.Label>
                            <div style={{ position: "relative" }}>
                              <Form.Control
                                onChange={(e) =>
                                  setwhenProblemOccurTime(e.target.value)
                                }
                                style={{
                                  fontSize: "1rem",
                                  border: "none",
                                  background: "transparent",
                                  borderBottom: "2px solid gray",
                                  borderRadius: "0",
                                  paddingLeft: "0",
                                }}
                                type="time"
                                required
                                value={whenProblemOccurTime}
                              />
                              <i
                                className="bi bi-clock"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  right: "0.5rem",
                                  transform: "translateY(-50%)",
                                }}
                              ></i>
                            </div>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid time.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      </div>
                    </Col> */}
                    {/* <Col md={6} className='pt-3 pb-3'>
                                            <h1 className='mb-3 complaintText'>Did You Sign Any Agreement?</h1>
                                            <div className="col-sm-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="yes" id="flexCheckDefault" checked={didSignAggrement} onChange={handleCheckboxChange2} />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">Yes</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="no" id="flexCheckChecked" checked={!didSignAggrement} onChange={handleCheckboxChange2} />
                                                    <label className="form-check-label" htmlFor="flexCheckChecked">No</label>
                                                </div>
                                            </div>
                                        </Col> */}
                  {/* </Row>

                  <Row className="mt-4 mb-4">
                    <Col md={6} className="pt-3 pb-3">
                      <h1 className="mb-3 complaintText">
                        Select A Type Which Is Most Relevant To Your Complaint
                      </h1>
                      <Form.Select
                        required
                        onChange={(e) => setcomplaintType(e.target.value)}
                        value={complaintType}
                        aria-label="Default select example"
                        style={{ fontFamily: "poppins" }}
                      >
                        <option disabled selected>
                          Open this select menu
                        </option>
                        <option value="Airline">Airline complaints</option>
                        <option value="Railway">Railway complaints</option>
                        <option value="Banking">Banking complaints</option>
                        <option value="Postal">Postal complaints</option>
                        <option value="Medical">Medical complaints</option>
                        <option value="Travel">Travel complaints</option>
                        <option value="Telecom">Telecom complaints</option>
                        <option value="E-Commerce">
                          E-Commerce complaints
                        </option>
                        <option value="Insurance">Insurance complaints</option>
                        <option value="Real-Estate">
                          Real-Estate complaints
                        </option>
                        <option value="Home Appliances">
                          Home appliances complaints
                        </option>
                        <option value="Electricity">
                          Electricity complaints
                        </option>
                        <option value="Government Service">
                          Government service complaints
                        </option>
                        <option value="LPG/Petroleum">
                          LPG/Petroleum complaints
                        </option>
                        <option value="Food Safety">
                          Food safety complaints
                        </option>
                        <option value="Mobile/Laptop">
                          Mobile/laptop complaints
                        </option>
                        <option value="Automobiles">
                          Automobiles complaints
                        </option>
                        <option value="Education">Education complaints</option>
                        <option value="Internet Service Provider">
                          Internet service providers complaints
                        </option>
                        <option value="D2H">D2H complaints</option>
                        <option value="Others">Others</option>
                      </Form.Select>
                    </Col>
                    <Col md={6} className="pt-3 pb-3">
                      <h1 className="mb-3 complaintText">
                        Please Describe Your Complaint In Detail
                      </h1>
                      <div className="col-sm-12">
                        <textarea
                          style={{
                            border: "2px solid gray",
                            padding: "10px",
                            height: 100,
                            fontFamily: "poppins",
                          }}
                          name=""
                          placeholder="Describe your issue"
                          id=""
                          required
                          onChange={(e) =>
                            setcomplaintDescription(e.target.value)
                          }
                          value={complaintDescription}
                          cols="30"
                          rows="10"
                        ></textarea>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <h5 className="section-heading complaintText2">
                        Authorizing CRO
                      </h5>
                      <div
                        className="d-flex align-center justify-content-between"
                        style={{ background: "#F3F4F6", padding: "2rem" }}
                      >
                        <div className="col-sm-12">
                          <div
                            style={{
                              background: "#fff",
                              padding: "2rem",
                              borderRadius: "15px",
                              height: "200px",
                              overflow: "hidden",
                              overflowY: "auto",
                            }}
                          >
                            <h4
                              className="text-center mb-4"
                              style={{ fontWeight: "800", fontSize: "1.1rem" }}
                            >
                              Authorization to CRO- Consumer Rights
                              Organization/ Upbhokta Adhikar Sangthan : -
                            </h4>
                            <p
                              style={{
                                lineHeight: "1.4",
                                color: "rgb(94, 94, 94)",
                                marginBottom: "2rem",
                              }}
                            >
                              I do hereby Authorize and appoint Consumer Rights
                              Organization/ Upbhokta Adhikar Sangthan
                              (hereinafter referred and called as “CRO”) for
                              following acts on my behalf. To act, appear and
                              plead in the above-noted consumer case in any
                              Court or in any other Forum/Tribunal/commission in
                              which the same may be tried or heard and also in
                              the appellate Court including state
                              commission/national commission subject to payment
                              of fees separately for each Court by me/us.
                            </p>
                            <p
                              style={{
                                lineHeight: "1.4",
                                color: "rgb(94, 94, 94)",
                                marginBottom: "2rem",
                              }}
                            >
                              To sign, file, verify and present pleadings,
                              appeals, cross-objections or petitions for
                              executions review revision, withdrawal, compromise
                              or other petitions or affidavits or other
                              documents as may be deemed necessary or proper for
                              the purpose of the said case in all its stages
                              subject to payment of fees for each stage. To file
                              and take back documents, to admit and/or deny the
                              documents of the opposite party.
                            </p>
                            <p
                              style={{
                                lineHeight: "1.4",
                                color: "rgb(94, 94, 94)",
                                marginBottom: "2rem",
                              }}
                            >
                              To withdraw or compromise the said case or submit
                              to arbitration any differences or disputes that
                              may arise touching or in any manner relating to
                              the said case. To take execution proceedings.
                            </p>
                            <p
                              style={{
                                lineHeight: "1.4",
                                color: "rgb(94, 94, 94)",
                                marginBottom: "2rem",
                              }}
                            >
                              To deposit, draw and receive monthly cheques, cash
                              and grant receipts thereof and to do all other
                              acts and things which may be necessary to be done
                              for the progress and in the course of the
                              prosecution of the said case.
                            </p>
                            <p
                              style={{
                                lineHeight: "1.4",
                                color: "rgb(94, 94, 94)",
                                marginBottom: "2rem",
                              }}
                            >
                              To appoint and instruct any other Legal
                              Practitioner authorizing him to exercise the power
                              and authority hereby conferred upon the Advocate
                              whenever he may think fit to do so and to sign the
                              power of attorney on our behalf
                            </p>
                            <p
                              style={{
                                lineHeight: "1.4",
                                color: "rgb(94, 94, 94)",
                                marginBottom: "2rem",
                              }}
                            >
                              And I/We the undersigned do hereby agree to
                              rectify and confirm all acts done by the CRO and
                              Advocate appointed by CRO or his substitute in the
                              matter as my/our own acts, as if done by me/us to
                              all intents and purposes. And I/We undertake that
                              I/We or my/our duly authorized agent would appear
                              in Court on all hearings and will inform the CRO
                              for appearance when the case is called. And I/We
                              the undersigned do hereby agree not to hold the
                              CRO/advocate or his substitute responsible for the
                              result of the said case
                            </p>
                            <p
                              style={{
                                lineHeight: "1.4",
                                color: "rgb(94, 94, 94)",
                                marginBottom: "2rem",
                              }}
                            >
                              The adjournment costs and Legal expenses whenever
                              ordered by the Court shall be of the CRO/Advocate
                              Appointed by CRO, which he shall receive and
                              retain for himself. And I/We the undersigned to
                              hereby agree that in the event of the whole or
                              part of the fee agreed by me/us to be paid to the
                              CRO/advocate remaining unpaid he shall be entitled
                              to withdraw from the prosecution of the said case
                              until the same is paid up. The fee settled is only
                              for the above case and above Court. I/we hereby
                              agree that once fee is paid, I/We will not be
                              entitled for the refund of the same in any case
                              whatsoever and if the case prolongs for more than
                              3 years the original fee shall be paid again by
                              me/us. I hereby fully agreed to give my complaint
                              details to publish on social media on my behalf by
                              the CRO
                            </p>
                            <p
                              style={{
                                lineHeight: "1.4",
                                color: "rgb(94, 94, 94)",
                                marginBottom: "2rem",
                              }}
                            >
                              I am giving my concern to the CRO to publish my
                              details in survey report, media report, on
                              websites etc.,
                            </p>
                            <p
                              style={{
                                lineHeight: "1.4",
                                color: "rgb(94, 94, 94)",
                                marginBottom: "2rem",
                              }}
                            >
                              Declaration by me :- I the above named complainant
                              hereby declare and undertake that all the details
                              and documents furnished here are true and correct
                              to the best of my knowledge and I rely on it
                            </p>
                          </div>
                          <Form.Group className="mt-4">
                            <Form.Check
                              required
                              label="I Authorize CRO Consumer Rights Organization to send notice and fight case on behalf of myself"
                              feedback="You must agree before submitting."
                              feedbackType="invalid"
                            />
                          </Form.Group>
                        </div>

                      </div>
                      <h5
                        className="section-heading complaintText2"
                        style={{ marginTop: 50 }}
                      >
                        Declaration
                      </h5>
                      <div
                        className="d-flex align-center justify-content-between"
                        style={{ background: "#F3F4F6", padding: "2rem" }}
                      >
                        <div className="col-sm-12">
                          <Form.Group className="me-2">
                            <Form.Check
                              required
                              label=" I declare that all details filled and files attached in the complaint form are true and correct"
                              feedback="You must agree before submitting."
                              feedbackType="invalid"
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <h5
                        className="section-heading complaintText2"
                        style={{ marginTop: 50 }}
                      >
                        Consent And Verification
                      </h5>
                      <div
                        className="d-flex align-center justify-content-between"
                        style={{ background: "#F3F4F6", padding: "2rem" }}
                      >
                        <div className="col-sm-12">
                          <p
                            style={{
                              lineHeight: "1.4",
                              color: "rgb(94, 94, 94)",
                            }}
                          >
                            I affirm, under the penalties for perjury, that the
                            foregoing representations, and those in all
                            attachments, are true. The information i have
                            provided in this complaint form is based upon my
                            personal knowledge. I consent to the Consumer Rights
                            Organisation Obtaining or releasing any information
                            in furtherance of the disposition of this complaint.
                            I understand if i provide any wrong information than
                            i will be liable for legal proceedings against me
                          </p>
                          <Form.Group className="mt-4">
                            <Form.Check
                              required
                              label="I declare that all details filled and files attached in the complaint form are true and correct"
                              feedback="You must agree before submitting."
                              feedbackType="invalid"
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {loading ? (
            <Spinner
              animation="border"
              role="status"
              className="mx-auto d-block"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <Button
              type="submit"
              className="mx-auto d-block mt-4 complaintSubmitBtn"
            >
              Submit form
            </Button>
          )}
        </Container>
      </Form> */}

      <div className="container-fluid p-5 mt-5">
        <h1 className="text-center mb-5" style={{ fontWeight: "800" }}>
          Top tips
        </h1>
        <div className="row">
          <div className="col">
            <ul>
              <li
                style={{
                  fontSize: ".95rem",
                  lineHeight: "1.8",
                  color: "rgb(94, 94, 94)",
                  marginBottom: "1rem",
                }}
              >
                1. Give the company an opportunity to put things right. If you
                are unsure about how to make a complaint, contact our Consumer
                Support team on +91 9977 252 252. They can guide you through
                making an effective complaint (Please note: calls will be
                recorded In order to improve the quality of our service and for
                business purposes)
              </li>
              <li
                style={{
                  fontSize: ".95rem",
                  lineHeight: "1.8",
                  color: "rgb(94, 94, 94)",
                  marginBottom: "1rem",
                }}
              >
                2. Ask the company for a copy of their complaints procedure so
                you can be sure that they are handling your complaint correctly.
              </li>
              <li
                style={{
                  fontSize: ".95rem",
                  lineHeight: "1.8",
                  color: "rgb(94, 94, 94)",
                  marginBottom: "1rem",
                }}
              >
                3. If you are complaining by telephone, make a note of what you
                want to say and how you want the problem to be solved.
              </li>
              <li
                style={{
                  fontSize: ".95rem",
                  lineHeight: "1.8",
                  color: "rgb(94, 94, 94)",
                  marginBottom: "1rem",
                }}
              >
                4. Make sure you have the necessary documents from the company
                ready (bills, letters etc). The documents may have a reference
                number that you may need when speaking to the company.
              </li>
              <li
                style={{
                  fontSize: ".95rem",
                  lineHeight: "1.8",
                  color: "rgb(94, 94, 94)",
                  marginBottom: "1rem",
                }}
              >
                5. Have a pen and paper ready so you can note the person's name
                you were talking to, and a record of what they said.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container-fluid p-5 mt-5 complaintTipBox">
        <h4 className="mb-4" style={{ color: "#fff" }}>
          WHAT HAPPENS WHEN YOU MAKE A COMPLAINT?
        </h4>
        <ul>
          <li className="mb-3" style={{ listStyle: "initial", color: "#fff" }}>
            We will contact the company concerned and discuss how we can resolve
            your complaint.
          </li>
          <li className="mb-3" style={{ listStyle: "initial", color: "#fff" }}>
            We will request copies of all correspondence exchanged in relation
            to your complaint.
          </li>
          <li className="mb-3" style={{ listStyle: "initial", color: "#fff" }}>
            We will keep you informed at every step of the complaint.
          </li>
          <li className="mb-3" style={{ listStyle: "initial", color: "#fff" }}>
            Remember: If you have contacted the company concerned and they have
            not solved the problem or you are not satisfied with their response,
            contact our Consumer Support team on +91 9977 252 252 or make a
            complaint online.
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
};

export default Complaints;
