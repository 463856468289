import React, { useEffect } from "react";
import Navbar from "../navbar/TopNavbar";
import Hero from "../hero/Hero";
import Welcome from "../welcome/Welcome";
import WoWeAre from "../woWeAre/WoWeAre";
import WhatWeDo from "../whatWeDo/WhatWeDo";
import WorkingFor from "../workingFor/WorkingFor";
import Complaint from "../complaint/Complaint";
import Feedback from "../feedback/Feedback";
import Footer from "../footer/Footer";
import YouTube from "../youtube";
import Marqee from "../Marqee/Marqee";
import Hero2 from "../hero/Hero2";
import { GoogleOAuthProvider,GoogleLogin,useGoogleOneTapLogin  } from '@react-oauth/google'
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "../../assets/img/logo.png";
import {Helmet} from "react-helmet";
const HomaPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const login = useGoogleOneTapLogin({
    onSuccess: (response) => {
      console.log('Google One Tap Login Success', response);
    },
    onError: (error) => {
      console.log('Google One Tap Login Error', error);
    },
  })

  return (
    <>
<Helmet>
    <title>Consumer Right Origination - Protect Your Rights</title> 
    <meta name="description" content="Learn about the origin and history of consumer rights. Explore laws, tips, and resources to protect your rights and resolve complaints effectively." />
    {/* <!-- Open Graph Metadata --> */}
    <meta property="og:title" content="Consumer Right Origination - Protect Your Rights" />
    <meta property="og:description" content="Discover the history of consumer rights and gain insights into how you can safeguard your rights as a consumer." />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://example.com/consumer-right-origination" />
    <meta property="og:image" content="https://example.com/images/consumer-rights.jpg" />
    {/* <!-- Twitter Metadata --> */}
    <meta name="twitter:card" content="CRO-Consumer Rights Organisation" />
    <meta name="twitter:title" content="CRO-Consumer Rights Organisation" />
    <meta name="twitter:description" content="Empowering Consumers, Enforcing Rights. CRO - Consumer Rights Organization | Voluntary organization working PAN India.  Links, Repost not endorsements" />
    <meta name="twitter:image" content="https://x.com/thecroindia/status/1876901746624344347/photo/1" />
</Helmet>
      <Navbar />
      {/* <Marqee /> */}
      <Hero2/>
      <Welcome />
      <WoWeAre />
      <WhatWeDo />
      <WorkingFor />
      <Complaint />
      <YouTube />
      <Feedback />
      <Footer />
      <FloatingWhatsApp
          phoneNumber="919977252252"
          accountName="CRO"
          allowClickAway
          notification
          notificationDelay={60000}
          avatar={logo}
        />

      {/* <GoogleLogin
              onSuccess={(response) => {
                console.log(response);
                // Handle Google login success here
              }}
              onError={(error) => {
                console.error('Google Login Failed:', error);
              }}
              useOneTap
            /> */}

    </>
  );
};

export default HomaPage;
