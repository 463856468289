import React, { useEffect, useState } from 'react';
import style from './style2.module.css';
import parashoot from '../../assets/img/rb_5000.png';
import cloud from '../../assets/img/cloud.png';
import cloud2 from '../../assets/img/cloud2.png';
import bird from '../../assets/img/bird.gif';
import brush from "../../assets/img/brush.png";
import { TypeAnimation } from 'react-type-animation';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa6";

export default function Hero2() {
  const [scrollY, setScrollY] = useState(0);
  const [firstScroll, setFirstScroll] = useState(true); // Track if it's the first scroll
  const [contentChanged, setContentChanged] = useState(false); // To track if the content has changed

  useEffect(() => {
    const handleScroll = (event) => {
      if (firstScroll) {
        event.preventDefault(); // Prevent page scroll on first scroll
        setFirstScroll(false);  // After the first scroll, allow page scroll
        setContentChanged(true); // Change content on the first scroll

        // Remove the event listener after handling the first scroll
        setTimeout(() => {
          window.removeEventListener('wheel', handleScroll);
        }, 100); // Allow smooth transition before removing listener
      }
    };

    // Listen to the wheel event for scrolling
    window.addEventListener('wheel', handleScroll, { passive: false });

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [firstScroll]);

  // Track scroll position after the first scroll
  useEffect(() => {
    if (!firstScroll) {
      const handlePageScroll = () => {
        setScrollY(window.scrollY);
      };
      window.addEventListener('scroll', handlePageScroll);
      return () => {
        window.removeEventListener('scroll', handlePageScroll);
      };
    }
  }, [firstScroll]);

  const navigate =  useNavigate()

  return (
    <div className={style.main}>
      <div className={style.box}>
        {/* Parachutes */}
        <img
          src={parashoot}
          alt="Left Parachute"
          className={`${style.parachute} ${style.leftParachute}`}
          style={{
            transform: `translateX(-${scrollY * 0.2}px)`,
          }}
        />
        <img
          src={parashoot}
          alt="Right Parachute"
          className={`${style.parachute} ${style.rightParachute}`}
          style={{
            transform: `translateX(${scrollY * 0.2}px)`,
          }}
        />

        {/* Clouds */}
        <img
          src={cloud2}
          alt="Cloud 1"
          className={style.cloud}
          style={{
            transform: `translateX(${scrollY * 0.3}px)`,
          }}
        />
        <img
          src={cloud}
          alt="Cloud 2"
          className={style.cloud}
          style={{
            transform: `translateX(-${scrollY * 0.3}px)`,
          }}
        />

        {/* Birds */}
        <img src={bird} alt="Bird 1" className={`${style.bird} ${style.bird1}`} />
        <img src={bird} alt="Bird 2" className={`${style.bird} ${style.bird2}`} />

        {/* Content */}
        {contentChanged ? (
          <div className={style.content}>
            <h1 className={style.headline}>Consumer Rights <br /> Organisation</h1>
            <p style={{color:"#000",padding:10,fontWeight:"700"}}>Facing an issue? We're here to help! File your complaint with ease and let us stand up for your consumer rights. Your voice matters—let it be heard today! & Join a community that champions consumer rights! As a member, you'll gain exclusive access to resources, support, and advocacy. Empower yourself—become a member now!</p>
            <button className={style.btn} onClick={()=>navigate('/consumer-complaint-with-consumer-right-helpline')}><FaArrowRight /> File a Complaint</button>
            <button className={style.btn1} onClick={()=>navigate('/complaint/register')}> <FaArrowRight /> Become a Member</button>
            
          </div>
        ) : (
          <div className={style.content}>
            <h1 className={style.headline} >Consumer Rights <br /> Organisation</h1>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={brush} style={{ height: 130, width: "140%", position: "relative" }} />
              <TypeAnimation
                sequence={[
                  'Consumer Right to Fair Treatment',
                  1000,
                  'Consumer Right to Access Information',
                  1000,
                  'Consumer Right to Product Quality',
                  1000,
                  'Consumer Right to Timely Redress',
                  1000,
                  'Consumer Right to Transparent Pricing',
                  1000,
                  'Consumer Right to Protection Against Fraud',
                  1000,
                  'Consumer Right to Equal Treatment',
                  1000,
                  'Consumer Right to Protection from Exploitation',
                  1000,
                  'Consumer Right to Accountability from Service Providers',
                  1000,
                  'Consumer Right to Sustainable and Ethical Products',
                  1000,
                ]}
                wrapper="span"
                speed={50}
                style={{ fontSize: '19px', display: 'inline-block', position: "absolute", fontWeight: "bold", fontFamily: "Poppins, serif" }}
                repeat={Infinity}
              />


            </div>
          </div>
        )}
      </div>
    
    </div>
  );
}