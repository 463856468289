import Footer from "../footer/Footer";
import Navbar from "../navbar/TopNavbar";
import React, { useEffect, useState } from "react";
import bill from "../../assets/bill.gif";
import bill2 from "../../assets/bill2.png";
import { Link } from "react-router-dom";
import { IoIosArrowDown,IoIosArrowUp  } from "react-icons/io";


const BillLekeDekho = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const[toggle,setToggle] =useState(false)

  return (
    <>
      <Navbar />
      <br></br>
       
               <div className="movement-min-navbar">
            <ul >
              
            <li>
                <Link to="/movement/billlekedekho" style={{color:"#fff"}}>
                Public engagement campaign on needs of Indian Smokers /
                </Link>
              </li>
              <li>
                <Link to="/movement/billlekedekho" style={{color:"#fff"}}>
                Bill Leke Dekho /
                </Link>
              </li>
              <li>
                <Link to="/movement/educationSys"  style={{color:"#fff"}}>
                Education System /
                </Link>
              </li>

              <li >
                <Link  to="/movement/foodAdult" style={{color:"#fff"}}>
                   Food Adulteration Matters /
                </Link>
              </li>

              <li >
                <Link  to="/movement/foodAdult" style={{color:"#fff"}}>
                National Integration 
                </Link>
              </li>
            </ul>
            </div>

            <div className="movement-min-navbar-mobile" style={{height:toggle?300: 50}} >
            <ul >
            <li onClick={()=>setToggle(!toggle)}>
              <div style={{width:"100%",display:"flex",alignItems:"center",height:50,color:"#fff",justifyContent:"space-between"}}>
              See More Option
              {
                toggle?
                <IoIosArrowUp style={{marginRight:10}} size={20}/>
                :
               
                <IoIosArrowDown style={{marginRight:10}} size={20}/>
                
              }
              </div>
                
              </li>
            <li>
                <Link to="/movement/billlekedekho" className="movement-link" style={{color:"#fff"}}>
                Public engagement campaign on needs of Indian Smokers 
                </Link>
              </li>
              <li>
                <Link to="/movement/billlekedekho" className="movement-link" style={{color:"#fff"}}>
                Bill Leke Dekho 
                </Link>
              </li>
              <li>
                <Link to="/movement/educationSys" className="movement-link" style={{color:"#fff"}}>
                Education System 
                </Link>
              </li>

              <li >
                <Link  to="/movement/foodAdult" className="movement-link" style={{color:"#fff",}}>
                   Food Adulteration Matters 
                </Link>
              </li>

              <li >
                <Link  to="/movement/foodAdult" className="movement-link"  style={{color:"#fff"}}>
                National Integration 
                </Link>
              </li>
            </ul>
            </div>
      <div className="container mt-5 ">
        <h4 className="text-center fw-bolder my-4">BILL LEKE DEKHO</h4>

        <div className="row mt-4 flex-md-row  flex-column-reverse ">
          <div className="col-md-7 mb-md-0 mb-4">
            <p style={{ fontSize: "1rem" }}>
              We are in a country where consumers are taken for a ride as they
              are not aware about their basic consumer rights. Consumers are
              being looted by business mafias by spreading rumors about the
              legitimate bills & taxes, because of that many consumers refrain
              from taking a bill thinking that they'll have to pay more money
              unnecessarily.
            </p>
            <p style={{ fontSize: "1rem" }}>
              A country benefits when the citizen pay their taxes, as it becomes
              a source of income for the development of the country.
              Unfortunately, In our country we have businesses running where
              people for their personal benefit fool people by not providing any
              type of receipt to the consumer for their purchases. In India we
              find many small businesses flourishing by hoodwinking the consumer
              & the government by finding ways to escape paying taxes, and one
              such way is by not providing bills, the consumer as well
              contributes to this apathy by foregoing the bill assuming that
              they have been given some sort of discount.
            </p>
          </div>
          <div className="col-md-5 mb-md-0 mb-4 position-relative ps-md-5 d-flex align-items-center justify-content-center ">
            <img src={bill} height={350} width={400} alt="" />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-5 mb-md-0 mb-4 position-relative pe-md-5 d-flex align-items-center justify-content-center ">
            <img src={bill2} height={350} width={400} alt="" />
          </div>
          <div className="col-md-7 mb-md-0 mb-4">
            <p style={{ fontSize: "1rem" }}>
              In order to spread awareness the Consumer Rights Organisation
              started a campaign pan India basis known as "BILL LEKE DEKHO" in
              year 2013, wherein the organisation members throughout the country
              conducted 168 consumer awareness programs, 1072 signature
              campaigns, 126 seminars and 93 public meetings in 18 states to
              educate consumer that their rights start when they receive a bill
              for whatever items purchased, and this will protect & safeguard
              them from unethical business practices.
            </p>
            <p style={{ fontSize: "1rem" }}>
              The Consumer Rights Organisation in the endeavor to educate
              consumers informed about the benefits of taking a receipt as it
              gives the consumers a Lawful Right in case of redressal, and
              protects the consumer from any unwarranted issues such as damaged
              goods, unfulfilled services etc. The consumers were also made
              aware that by taking a bill they would contribute to the
              development of infrastructure and defense capabilities of the
              country . It also prevents money laundering and terrorist
              activities.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BillLekeDekho;
